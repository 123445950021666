import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import ReactTooltip from 'react-tooltip';
import { Translate } from 'react-localize-redux';

import ApiCall from '../../api/api';
import { endpoints } from '../../api/endpoints';
import TrainingListSideBarContainer from '../TrainingListSideBarContainer/TrainingListSideBarContainer';
import TrainingDetailCard from '../../components/layers/Trainings/TrainingDetailCard';
import TrainingCard from '../../components/layers/Trainings/TrainingCard';
import TrainingEmpty from '../../components/layers/Trainings/TrainingEmpty';
import { parseTime } from '../../utils/parseDate';
import { isNullOrUndefined } from '../../utils/checking';
import Loading from '../../components/shared/Loading/Loading';
import {
  getTrainings,
  updateTraining,
  addTraining,
  deleteTraining
} from '../../redux/actions/trainings';
import ConfirmModal from '../../components/shared/Modal/ConfirmModal';

// Sort training list by nbSession desc.
const compareNbSession = (a, b) => {
  if (a.nbSession > b.nbSession)
    return -1;
  if (a.nbSession < b.nbSession)
    return 1;
  return 0;
}

class TrainingListContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false, // Toogle edition mode.
      isModalOpen: false, // Opening state of the modal.
      training: [], // Selected training data.
      prevTraining: [], // Backup selected data (for editing).
      isConfirmModalOpen: false, // Opening state of the confirm modal.
      selectedId: null, // Selected trainig ID for deleting.
      showList: false // Show trainings list.
    };

    this.handleClickModalOpen = this.handleClickModalOpen.bind(this);
    this.handleOpenConfirmModal = this.handleOpenConfirmModal.bind(this);
    this.handleCloseConfirmModal = this.handleCloseConfirmModal.bind(this);
    this.handleToggleEdit = this.handleToggleEdit.bind(this);
    this.handleUpdateTrainingState = this.handleUpdateTrainingState.bind(this);
    this.handleClickDelete = this.handleClickDelete.bind(this);
  }

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.getTrainings(this.props.user).then(
        () => {this.setState({showList: true});}
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    ReactTooltip.rebuild();
    if (prevState.hidden !== this.state.hidden) {
      // The tooltips need to be rebuilt because either the relative component
      // is now being rendered.
      ReactTooltip.rebuild();
    }
  }

  /**
   * Filter selected categories / display all categories.
   * @param {*} currentCategory category object
   */
  isSelectedCategory(currentCategory) {
    const selectedCategories = this.props.categories.filter(
      category => category.isSelected
    );
    const hasCategories = selectedCategories.some(
      category => currentCategory.libelle === category.name
    );

    return (
      (selectedCategories.length > 0 && hasCategories) ||
      selectedCategories.length === 0
    );
  }

  handleOpenConfirmModal(id) {
    this.setState({
      isConfirmModalOpen: true,
      selectedId: id
    });
  }

  handleCloseConfirmModal() {
    this.setState({
      isConfirmModalOpen: false,
      selectedId: null
    });
  }

  /**
   * Open Modal after retreiving training data.
   * @param {object} e event react data
   * @param {number} id training id. "Null" if new training.
   */
  async handleClickModalOpen(e, id) {
    e.preventDefault();

    if (isNullOrUndefined(id)) {
      // Create a new training.
      this.setState({
        isModalOpen: true,
        editMode: true,
        training: {
          trainingId: null,
          title: '',
          duration: { hour: 1, minutes: 0 },
          description: '',
          category: { value: null, text: null },
          format: { value: null, text: null },
          type: { value: null, text: null },
          status: { value: null, text: null },
          provider: { value: null, text: null },
          plan: '',
          skills: null,
          certification: null,
          concernedPublic: 'Tout public',
          nbSession: 0
        }
      });
    } else {
      try {
        let response = await ApiCall.get(endpoints(id).GETTRAINING);
        // Open an existing training.
        const formatedTraining = this.mapTrainingDataForDisplay(response.data);
        this.setState({
          isModalOpen: true,
          editMode: false,
          training: formatedTraining,
          prevTraining: Object.assign({}, formatedTraining)
        });
      } catch (e) {
        console.error(e);
      }
    }
  }

  /**
   * Close modal and reset state.
   */
  handleClickModalClose = e => {
    e.preventDefault();
    this.setState({ isModalOpen: false });
  };

  /**
   * Handle click for edit mode.
   */
  handleToggleEdit = e => {
    e.preventDefault();
    this.toggleEdit();
  };

  /**
   * Toggle edit mode.
   */
  toggleEdit() {
    if (this.state.editMode) {
      // Cancel training edited values. Refill with old values.
      this.setState(state => ({
        training: Object.assign({}, state.prevTraining)
      }));
    }

    this.setState(state => ({ editMode: !state.editMode }));
  }

  /**
   * Update state for any change in training data.
   */
  handleUpdateTrainingState = e => {
    const field = e.target.name;
    const training = this.state.training;

    const setValue = () => {
      const fields = {
        duration: () => {
          training[field] = e.target.valueAsObject;
        },
        default: () => {
          training[field] =
            e.target.tagName.toLowerCase() === 'select'
              ? {
                  value: e.target.selectedOptions[0].value,
                  text: e.target.selectedOptions[0].text
                }
              : e.target.value;
        }
      };

      return (fields[field] || fields['default'])();
    };

    setValue();
    this.setState({ training: training });
  };

  /**
   * Save data.
   */
  handleClickSave = (e, formEl) => {
    e.preventDefault();

    if (formEl.checkValidity()) {
      if (isNullOrUndefined(this.state.training.trainingId)) {
        this.props.addTraining(
          this.mapTrainingDataForSaving(this.state.training)
        );
      } else {
        this.props.updateTraining(
          this.mapTrainingDataForSaving(this.state.training)
        );
      }

      this.setState(
        state => ({ prevTraining: Object.assign({}, state.training) }),
        () => {
          this.toggleEdit();
        }
      );
    }
  };

  handleClickDelete = () => {
    this.props.deleteTraining(this.state.selectedId);

    this.setState({
      isConfirmModalOpen: false,
      selectedId: null
    });
  }

  /**
   * training data mapping to display.
   */
  mapTrainingDataForDisplay = data => ({
    trainingId: data.id,
    title: data.intitule,
    duration: parseTime(data.dureeH),
    description: data.description,
    category: {
      value: data.categorie.id.toString(),
      text: data.categorie.libelle
    },
    format: { value: data.format.id.toString(), text: data.format.libelle },
    type: {
      value: data.typeFormation.id.toString(),
      text: data.typeFormation.libelle
    },
    status: { value: data.statut.id.toString(), text: data.statut.libelle },
    provider: {
      value: data.organisme.id.toString(),
      text: data.organisme.libelle
    },
    plan: data.plan,
    skills: data.competences,
    certification: data.certification,
    concernedPublic: data.publicConcerne,
    nbSession: data.nbSession
  });

  /**
   * training data mapping to save.
   */
  mapTrainingDataForSaving = data => ({
    id: data.trainingId,
    username: this.props.user.name,
    intitule: data.title,
    dureeH: Number(
      (data.duration.hour + data.duration.minutes / 60).toFixed(2)
    ),
    description: data.description,
    categorie: {
      id: parseInt(data.category.value, 10),
      libelle: data.category.text
    },
    format: { id: parseInt(data.format.value, 10), libelle: data.format.text },
    typeFormation: {
      id: parseInt(data.type.value, 10),
      libelle: data.type.text
    },
    statut: { id: parseInt(data.status.value, 10), libelle: data.status.text },
    organisme: {
      id: parseInt(data.provider.value, 10),
      libelle: data.provider.text
    },
    plan: data.plan,
    competences: data.skills,
    certification: data.certification,
    publicConcerne: data.concernedPublic,
    nbSession: 0
  });

  displayTrainings = () => {
    let trainings = this.props.trainings
        .sort(compareNbSession)
        .filter(training => this.isSelectedCategory(training.categorie))
        .map((data, index) => (
          <TrainingCard
            key={index}
            data={this.mapTrainingDataForDisplay(data)}
            onHandleClickModalOpen={this.handleClickModalOpen}
            onDeleteTraining={this.handleOpenConfirmModal}
          />
        ));

    // Display no sessions template.
    if (trainings.length === 0) {
      trainings = <TrainingEmpty />
    }

    return trainings;
  }

  render() {
    return (
      <div className="d-flex flex-column flex-md-row justify-content-center mt-2">
        <div className="sidebar-element mb-2 mr-md-2 mb-md-0">
          <TrainingListSideBarContainer
            onHandleClickModalOpen={this.handleClickModalOpen}
          />
        </div>
        <div className="content-element flex-column flex-md-row">
          <ReactTooltip
            id="programTip"
            place="bottom"
            type="dark"
            effect="float"
            className="preline"
          />
          {this.state.showList && this.displayTrainings()}
          {!this.state.showList && <Loading />}
        </div>
        <Modal
          open={this.state.isModalOpen}
          onClose={this.handleClickModalClose}
          center
          closeIconSize={16}
          closeOnOverlayClick={false}
          classNames={{
            modal: 'modal-content',
            overlay: 'modal-overlay',
            closeButton: 'modal-closebutton clickable'
          }}>
          <TrainingDetailCard
            data={this.state.training}
            editMode={this.state.editMode}
            onToggleEdit={this.handleToggleEdit}
            onChange={this.handleUpdateTrainingState}
            onClickSave={this.handleClickSave}
          />
        </Modal>
        <ConfirmModal
          classNames={{confirmButton: 'btn-warning'}}
          isModalOpen={this.state.isConfirmModalOpen}
          onClickModalClose={this.handleCloseConfirmModal}
          onConfirm={this.handleClickDelete}
          onCancel={this.handleCloseConfirmModal}>
            <Translate id="confirmModal.onDeleteTraining" />
        </ConfirmModal>
      </div>
    );
  }
}

TrainingListContainer.propTypes = {
  user: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  trainings: PropTypes.array.isRequired,
  getTrainings: PropTypes.func,
  categories: PropTypes.array.isRequired,
  updateTraining: PropTypes.func,
  deleteTraining: PropTypes.func,
  addTraining: PropTypes.func
};

const mapStateToProps = state => ({
  user: state.user,
  isAuthenticated: state.auth.isAuthenticated,
  trainings: state.trainings,
  categories: state.categories,
  updateTraining: state.updateTraining,
  addTraining: state.addTraining
});

const mapDispatchToProps = dispatch => {
  return {
    getTrainings: user => { return dispatch(getTrainings(user)); },
    updateTraining: training => { dispatch(updateTraining(training)); },
    addTraining: training => { dispatch(addTraining(training)); },
    deleteTraining: id => { dispatch(deleteTraining(id)); }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainingListContainer);
