import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import App from './App';

import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route } from 'react-router-dom';

import { Provider } from 'react-redux';
import { LocalizeProvider } from 'react-localize-redux';
import configureStore from './redux/configureStore';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import { loadAPIErrors } from './redux/actions/error';
import ErrorHandler from './components/shared/Error/ErrorHandler';
import Interceptors from './api/interceptors';

const store = configureStore();
const persistor = persistStore(store);
const RESPONSE_INTERCEPTOR_NAME = 'errorManager';

// Response interceptor.
Interceptors.addResponseInterceptor(
  RESPONSE_INTERCEPTOR_NAME,
  (response) => {
    return response;
  },
  (error) => {
    store.dispatch(loadAPIErrors(error));
    return Promise.reject(error);
  }
);

// Wrap the rendering in a function:
const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LocalizeProvider store={store}>
          <BrowserRouter>
            <Route path="/" render={props => <Component {...props} />} />
          </BrowserRouter>
          <ErrorHandler />
        </LocalizeProvider>
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', renderApp(App));
}

// Render once
renderApp(App);