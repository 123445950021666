import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

export const TextInput = props => {
  const {
    editMode,
    className,
    onChange,
    debounceTimer,
    value,
    ...otherProps
  } = props;

  /**
   * Debounce onchange event.
   * @param {*} args onchange event args.
   */
  const debounceEvent = (...args) => {
    const debouncedEvent = debounce(...args);
    return e => {
      e.persist();
      return debouncedEvent(e);
    };
  };

  const handleChange = e => {
    onChange(e);
  };

  const input = (
    <input
      className={`form-control ${className}`}
      defaultValue={value}
      onChange={debounceEvent(handleChange, debounceTimer)}
      {...otherProps}
    />
  );

  const labelText = props.value || '';

  return editMode ? input : labelText;
};

TextInput.defaultProps = {
  className: '',
  debounceTimer: 50
};

TextInput.propTypes = {
  editMode: PropTypes.bool,
  className: PropTypes.any,
  debounceTimer: PropTypes.number
};
