import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import Moment from 'react-moment';

import { ClickButton } from '../../shared/Buttons/Index';
import { Icons } from '../../shared/Icons/Index';
import sessionStatus from '../../../enums/sessionStatus';
import { connect } from 'react-redux';

import roles, { roleEnum } from '../../../enums/roles';

const SessionDetailSideBar = props => {
  return (
    <div className="session-list-detail">
      <h1 className="px-2 rounded-top">
        <Translate id="sessionDetail.detailHeading" />
        {roles.hasAbility(props.user, roleEnum.ADMIN) && (
        <div className="float-right">
          <ClickButton
            icon={Icons.EDIT}
            iconOnly
            className="icon-size-small icon-reverse-color clickable"
            onClick={e => {
              e.preventDefault();
              props.enableEditMode();
            }} />
        </div>
        )}
      </h1>
      <div className="block-element px-2">
        <h2>
          <Translate id="sessionDetail.training" />
        </h2>
        {props.session && props.session.formation.intitule}
      </div>
      <div className="block-element px-2">
        <h2>
          <Translate id="sessionDetail.former" />
        </h2>
        {props.session && props.session.formateur}
      </div>
      <div className="block-element px-2">
        <h2>
          <Translate id="sessionDetail.status" />
        </h2>
        <div
          className={sessionStatus.getColor(
            props.session && props.session.statutSession.libelle
          )}>
          <strong>{props.session.statutSession.libelle}</strong>
        </div>
      </div>
      <div className="block-element px-2">
        <h2>
          {props.session &&
          props.session.dates &&
          props.session.dates.length > 1 ? (
            <Translate id="sessionDetail.dates" />
          ) : (
            <Translate id="sessionDetail.date" />
          )}
        </h2>
        {props.session.dates &&
          props.session.dates.map((date, index) => (
            <div key={index}>
              <Moment parse="YYYY-MM-DDTHH:mm:ssZ" format="DD/MM/YYYY HH:mm">
                {date.date}
              </Moment>{' '}
              (
              <Moment parse="YYYY-MM-DDTHH:mm:ss.SSSZ" format="H:mm">
                {date.duration.toISOString()}
              </Moment>
              )
            </div>
          ))}
      </div>
      <div className="block-element px-2">
        <h2>
          <Translate id="sessionDetail.place" />
        </h2>
        {props.session.lieu}
      </div>
      <div className="block-element px-2">
        <h2>
          <Translate id="sessionDetail.limitRegistration" />
        </h2>
        {props.session.nbMax}
      </div>
      <div className="block-element px-2">
        <h2>
          <Translate id="sessionDetail.amount" />
        </h2>
        <div className="row px-3">
          {props.session.montantHT} <div className={'px-2'}>€ HT</div>
        </div>
        <h2>
          <Translate id="sessionDetail.amountFinance" />
        </h2>
        <div className="row px-3">
          {props.session.montantFinanceHT} <div className={'px-2'}>€ HT</div>
        </div>
        <h2>
          <Translate id="sessionDetail.funding" />
        </h2>
        {props.session.financement.libelle}
      </div>
      <div className={`block-element px-2 word-break ${props.nbSubscribers > 0 ? 'rounded-bottom' : ''}`}>
        <h2>
          <Translate id="sessionDetail.comment" />
        </h2>
        {props.session.commentaire}{' '}
      </div>
      { props.nbSubscribers === 0 && (
      <div className="block-element px-2 rounded-bottom">
        <nav>
          <ul>
            <li>
              <ClickButton
                icon={Icons.DELETE}
                label="sessionDetailCard.btnDelete"
                onClick={e => {
                  e.preventDefault();
                  props.onDeleteSession(props.session.id);
                }} />
            </li>
          </ul>
        </nav>
      </div>
      )}
    </div>
  );
};

SessionDetailSideBar.propTypes = {
  session: PropTypes.object,
  financers: PropTypes.array,
  users: PropTypes.array,
  enableEditMode: PropTypes.func,
  onDeleteSession: PropTypes.func,
  nbSubscribers: PropTypes.number,
  trainings: PropTypes.array,
  user: PropTypes.object
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(SessionDetailSideBar);
