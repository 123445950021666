import * as types from '../actions/actionTypes';
import { headersEnum } from '../../enums/headers';

const initialState = {};

export default (state = initialState, action) => {
  // Session or authentication failure error.
  if (action.type === types.API_ERROR && action.error.response.status === 401) {
    // Add session time out error message / login failed message
    action.error.response.headers[headersEnum.ERROR] = "userAccessDeniedException";
    return { ...state, ...action.error };
  }

  // Other supported error code.
  const supportedErrorCodeList = [400, 504];
  if ((action.type === types.API_ERROR && supportedErrorCodeList.includes(action.error.response.status)) || action.type === types.UI_ERROR) {
    return { ...state, ...action.error };
  }

  if (action.type === types.CLEAR_ERROR) {
    return initialState;
  }

  return state;
};
