import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TrainingListSideBar from '../../components/layers/Trainings/TrainingListSideBar';
import { selectedCategorySuccess } from '../../redux/actions/categories';

class TrainingListSideBarContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickCategory = this.handleClickCategory.bind(this);
    this.state = {
      categories: []
    };
  }

  handleClickCategory(categoryName) {
    this.setState({
      categories: this.props.categories.map(category => {
        if (category.name === categoryName.name) {
          category.isSelected = !category.isSelected;
        }
        this.props.updateSelectedCategory(category);

        return category;
      })
    });
  }

  render() {
    return (
      <TrainingListSideBar
        categories={this.props.categories}
        onHandleClickCategory={this.handleClickCategory}
        onHandleClickModalOpen={this.props.onHandleClickModalOpen}
      />
    );
  }
}

TrainingListSideBarContainer.propTypes = {
  categories: PropTypes.array,
  updateSelectedCategory: PropTypes.func,
  onHandleClickModalOpen: PropTypes.func
};

const mapStateToProps = state => ({
  categories: state.categories
});

const mapDispatchToProps = dispatch => {
  return {
    updateSelectedCategory: category => {
      dispatch(selectedCategorySuccess(category));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainingListSideBarContainer);
