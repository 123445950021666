import React from 'react'
import PropTypes from 'prop-types'

import Modal from 'react-responsive-modal';
import { ClickButton } from '../../shared/Buttons/Index';
import { Icons } from '../../shared/Icons/Index';
import cx from 'classnames';

const ConfirmModal = props => {
  return (
    <Modal
        open={props.isModalOpen}
        onClose={props.onClickModalClose}
        center
        closeIconSize={16}
        closeOnOverlayClick={false}
        classNames={{
            modal: 'modal-content modal-content-confirm',
            overlay: 'modal-overlay modal-overlay-confirm',
            closeButton: 'modal-closebutton modal-closebutton-confirm clickable'
        }}>
        <div className="d-flex flex-column d-flex justify-content-around d-block mt-4">
            <div className="d-flex mb-4">
                <div className="d-flex align-items-center">
                    { props.children }
                </div>
            </div>
            <div className="d-flex d-flex justify-content-around">
                <ClickButton
                    label="confirmModal.cancel"
                    className={cx("btn", "btn-secondary", props.classNames.cancelButton)}
                    icon={Icons.CANCEL}
                    onClick={e => {
                        e.preventDefault();
                        props.onCancel();
                    }}
                />
                <ClickButton
                    label="confirmModal.confirm"
                    className={cx("btn", "btn-primary", props.classNames.confirmButton)}
                    icon={Icons.CHECKMARK}
                    onClick={e => {
                        e.preventDefault();
                        props.onConfirm();
                    }}
                />
            </div>
        </div>
    </Modal>
  )
}

ConfirmModal.propTypes = {
    children: PropTypes.node,
    isModalOpen: PropTypes.bool,
    classNames: PropTypes.object,
    onClickModalClose: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
}

ConfirmModal.defaultProps = {
    classNames: {}
}

export default ConfirmModal