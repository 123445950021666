import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

import InputMask from 'react-input-mask';

import { Icons } from '../../shared/Icons/Index';
import { ClickButton, LinkButton } from '../../shared/Buttons/Index';
import sessionStatus from '../../../enums/sessionStatus';

import Select from 'react-select';

import { isNullOrUndefined } from '../../../utils/checking';

import Flatpickr from 'react-flatpickr'

import 'flatpickr/dist/themes/material_red.css'
import '../../../styles/components/layers/Sessions/SessionDetailSideBarEdit.css';

const SessionDetailSideBarEdit = props => {
  let formEl = null;

  // Return training array index for selected ID.
  const getIndexFormation = () => {
    if (!props.trainings)
      return 0;
    return props.trainings.findIndex((training) => {
      return props.idFormation === training.id
    })
  }

  // Sort training list.
  const compare = (a, b) => {
    if (a.intitule.toLowerCase() < b.intitule.toLowerCase())
      return -1;
    if (a.intitule.toLowerCase() > b.intitule.toLowerCase())
      return 1;
    return 0;
  }

  /**
   * Render cancel button.
   * @param {boolean} iconOnly Display only icon.
   * @param {string} label Define label id for translation.
   * @param {string} classNames List of CSS class.
   */
  const cancelButton = (iconOnly, label, classNames) => {
    if (props.createMode){
      return <LinkButton
        path="/sessions-list"
        icon={Icons.CANCEL}
        className={classNames}
        iconOnly={iconOnly}
        label={label} />
    } else {
      return <ClickButton
        icon={Icons.CANCEL}
        className={classNames}
        iconOnly={iconOnly}
        label={label}
        onClick={e => {
          e.preventDefault();
          props.onCancelChanges();
        }} />
    }
  }

  return (
    <Translate>
      {({ translate }) => (
        <form
          className="session-list-detail"
          onSubmit={props.onSessionUpdate}
          ref={form => (formEl = form)}>
          <h1 className="px-2 rounded-top">
            <Translate id={props.createMode ? "sessionDetail.addHeading" : "sessionDetail.detailHeading"} />
            <div className="float-right">
              <div>
                {cancelButton(true, null, "icon-size-small icon-reverse-color clickable mr-1")}
                <ClickButton
                  icon={Icons.CHECKMARK}
                  className="icon-size-small icon-reverse-color clickable"
                  iconOnly
                  onClick={e => {
                    e.preventDefault();
                    props.onSessionUpdate(formEl);
                  }}
                />
              </div>
            </div>
          </h1>
          {props.trainings &&
            props.trainings.length > 0 && (
              <div className="block-element px-2">
                <h2>
                  <Translate id="sessionDetail.formation" />
                </h2>
                {!props.createMode && props.session.formation.intitule}
                {props.createMode && (
                  <Translate>
                  {({translate}) => (
                    <select
                      className="form-control"
                      name="formation"
                      defaultValue={(props.idFormation && props.trainings && props.trainings[getIndexFormation()]
                      && props.trainings[getIndexFormation()].intitule) || ""}
                      readOnly={!isNullOrUndefined(props.idFormation)}
                      disabled={!isNullOrUndefined(props.idFormation)}
                      required>
                        <option value="">{translate('sessionDetail.selectTraining')}</option>
                      {props.trainings.sort(compare).map((training, index) => (
                        <option key={index}>{training.intitule}</option>
                      ))}
                    </select>
                  )}
                  </Translate>
                )}
              </div>
          )}
          <div className="block-element px-2">
            <h2>
              <Translate id="sessionDetail.former" />
            </h2>
            <Select
              className="react-select-container"
              defaultValue={props.session.formateur ?
              {'value': props.session.formateur, 'label': props.session.formateur} :
              {'value': translate('sessionDetail.selectFormer'), 'label': translate('sessionDetail.selectFormer')}}
              isClearable
              isSearchable
              name="formateur"
              options={props.users}
            />
          </div>
          <div className="block-element px-2">
            <h2>
              <Translate id="sessionDetail.status" />
            </h2>
            <select
              className="form-control"
              name="statutSession"
              defaultValue={props.session.statutSession.libelle}>
              {sessionStatus.getEnums().map(([key, value]) => (
                <option key={key}>{value}</option>
              ))}
            </select>
          </div>
          <div className="block-element px-2">
            <h2>
              {props.session.dates && props.session.dates.length > 1 ? (
                <Translate id="sessionDetail.dates" />
              ) : (
                <Translate id="sessionDetail.date" />
              )}
            </h2>
            <div className="d-flex flex-column">
              {props.session.dates &&
                props.session.dates.map((date, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-center flex-row duration-input mb-1">
                    <Flatpickr
                      data-enable-time
                      value={new Date(date.date)}
                      options={{dateFormat: "d/m/Y H:i", altFormat: "d/m/Y H:i", time_24hr: true, allowInput: true, altInput: true,
                      altInputClass: "form-control clear-input" }}
                      onChange={e => {
                        props.onDateChange(e, index);
                      }}
                    />
                    <InputMask
                      name={'duration'}
                      className={'form-control duration mx-2 clear-input'}
                      mask="99:99"
                      maskChar={'_'}
                      alwaysShowMask={false}
                      defaultValue={`${date.duration
                        .getHours()
                        .toString()
                        .padStart(2, '0') || '00'}:${date.duration
                        .getMinutes()
                        .toString()
                        .padStart(2, '0') || '00'}`}
                      onChange={e => {
                        const values = e.target.value.split(':');
                        e.target['valueAsObject'] = {
                          hour: parseInt(values[0] || '0', 10),
                          minutes: parseInt(values[1] || '0', 10)
                        };
                        props.onDurationChange(index, e.target.valueAsObject);
                      }}
                    />
                    <ClickButton
                      icon={Icons.REMOVE}
                      iconOnly
                      className="icon-size-medium ml-2 align-middle"
                      onClick={e => {
                        e.preventDefault();
                        props.onDeleteDate(index);
                      }}
                    />
                  </div>
                ))}
            </div>
            <div>
              <ClickButton
                icon={Icons.ADD}
                label="sessionDetail.btnAddDate"
                className="icon-pictogram"
                onClick={e => {
                  e.preventDefault();
                  props.onAddDate();
                }}
              />
            </div>
          </div>
          <div className="block-element px-2">
            <h2>
              <Translate id="sessionDetail.place" />
            </h2>
            <textarea
              className="form-control"
              name="lieu"
              value={props.address}
              onChange={e => props.onPlaceChange(e)}
            />
          </div>
          <div className="block-element px-2">
            <h2>
              <Translate id="sessionDetail.limitRegistration" />
            </h2>
            <input
              className="form-control"
              name="nbMax"
              type="number"
              min="1"
              step="1"
              defaultValue={props.session.nbMax}
            />
            <div className="invalid-feedback">
              <Translate id="sessionDetail.limitRegistrationErrorMsg" />
            </div>
          </div>
          <div className="block-element px-2">
            <h2>
              <Translate id="sessionDetail.amount" />
            </h2>
            <div className="row px-3">
              <input
                className="form-control no-spinners col-6"
                name="montantHT"
                type="number"
                min="0"
                defaultValue={0}
              />{' '}
              <div
                className="align-price p-2">
                € HT
              </div>
            </div>
            <h2>
              <Translate id="sessionDetail.amountFinance" />
            </h2>
            <div className="row px-3">
              <input
                className="form-control no-spinners col-6"
                name="montantFinanceHT"
                type="number"
                min="0"
                defaultValue={0}
              />{' '}
              <div
                className="align-price p-2">
                € HT
              </div>
            </div>
            <h2>
              <Translate id="sessionDetail.funding" />
            </h2>
            <select
              className="form-control"
              name="financement"
              defaultValue={props.session.financement}>
              {props.financers &&
                props.financers.map((f, index) => (
                  <option key={index} value={f.id}>
                    {f.libelle}
                  </option>
                ))}
            </select>
          </div>
          <div className="block-element px-2">
            <h2>
              <Translate id="sessionDetail.comment" />
            </h2>
            <textarea
              className="form-control"
              name="commentaire"
              type="text"
              defaultValue={props.session.commentaire} />
          </div>
          <div className="block-element px-2 rounded-bottom">
            <nav>
              <ul>
                <li>
                  <ClickButton
                    icon={Icons.SAVE}
                    label="sessionDetail.btnSave"
                    onClick={e => {
                      e.preventDefault();
                      props.onSessionUpdate(formEl);
                    }}
                  />
                </li>
                <li>
                  {cancelButton(false, "sessionDetail.btnCancel", null)}
                </li>
              </ul>
            </nav>
          </div>
        </form>
      )}
    </Translate>
  );
};

SessionDetailSideBarEdit.defaultProp = {
  createMode: false
};

SessionDetailSideBarEdit.propTypes = {
  createMode: PropTypes.bool,
  session: PropTypes.object,
  financers: PropTypes.array,
  users: PropTypes.array,
  address: PropTypes.string,

  onSessionUpdate: PropTypes.func,
  onCancelChanges: PropTypes.func,
  onDateChange: PropTypes.func,
  onDurationChange: PropTypes.func,
  onAddDate: PropTypes.func,
  onDeleteDate: PropTypes.func,
  onPlaceChange: PropTypes.func,
  trainings: PropTypes.array,
  idFormation: PropTypes.number
};

export default SessionDetailSideBarEdit;
