import * as types from '../actions/actionTypes';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CATEGORIES_SUCCESS:
      return action.categories;

    case types.UPDATE_CATEGORY_SUCCESS:
      return [
        ...state.filter(category => category.id !== action.category.id),
        Object.assign({}, action.category)
      ];

    default:
      return state;
  }
};
