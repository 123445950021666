import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { isNullOrUndefined } from '../../../utils/checking';

export const SelectInput = props => {
  const {
    editMode,
    className,
    translateId,
    data,
    selectedValue,
    ...otherProps
  } = props;

  const selectInput = (
    <select
      className={`form-control ${className}`}
      defaultValue={selectedValue && selectedValue.value}
      {...otherProps}>
      {data &&
        data.map((option, index) => (
          <option key={index} value={option.value}>
            {option.text}
          </option>
        ))}
    </select>
  );

  const labelText = (selectedValue && selectedValue.text) || '';

  /**
   * Render a select translate input.
   */
  const displaySelect = !isNullOrUndefined(translateId) ? (
    <Translate>
      {({ translate }) => translate(translateId, { text: selectInput })}
    </Translate>
  ) : (
    selectInput
  );

  /**
   * Render a translate label.
   */
  const displayLabel = !isNullOrUndefined(translateId) ? (
    <Translate>
      {({ translate }) => translate(translateId, { text: labelText })}
    </Translate>
  ) : (
    labelText
  );

  return editMode ? displaySelect : displayLabel;
};

const dataShape = {
  value: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

SelectInput.defaultProps = {
  className: ''
};

SelectInput.propTypes = {
  editMode: PropTypes.bool,
  className: PropTypes.any,
  defaultValue: PropTypes.shape(dataShape),
  data: PropTypes.arrayOf(PropTypes.shape(dataShape)),
  translateId: PropTypes.string
};
