import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import { Icon, Icons } from '../../shared/Icons/Index';

const LinkWithText = () => (
    <Link to="/training-list">
      <Translate id="sessionCard.trainingLink" />
    </Link>
  );

const SessionEmpty = () => (
    <div className="session-card session-card-missing rounded p-2">
        <div className="d-flex flex-column align-items-center mt-4">
            <Icon icon={Icons.SADFACE} className="icon-size-big"/>
            <p style={{textAlign:'center'}}><Translate id="sessionCard.noValues" data={{nav: <LinkWithText />}} /></p>
        </div>
    </div>
  )

  export default SessionEmpty;