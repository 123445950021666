import * as types from './actionTypes';
import ApiCall from '../../api/api';
import { endpoints } from '../../api/endpoints';

export const getFundingProvidersSuccess = fundingProviders => ({
  type: types.GET_FUNDING_PROVIDERS_SUCCESS,
  fundingProviders
});

export function getFundingProviders() {
  return dispatch => {
    return ApiCall.get(endpoints().GETFUNDINGPROVIDERS)
      .then(response => {
        dispatch(getFundingProvidersSuccess(response.data));
      })
      .catch(error => {
        throw error;
      });
  };
}
