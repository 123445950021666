import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import { renderToStaticMarkup } from 'react-dom/server';
import { connect } from 'react-redux';
import { getTranslate, withLocalize } from 'react-localize-redux';
import { filter } from 'rxjs/operators';

import { loggingSuccess } from './redux/actions/auth';
import { setUserSuccess } from './redux/actions/user';
import { getCategories } from './redux/actions/categories';
import { getFormats } from './redux/actions/formats';
import { getTrainingTypes } from './redux/actions/trainingTypes';
import { getTrainingStatus } from './redux/actions/trainingStatus';
import { getFundingProviders } from './redux/actions/fundingProviders';
import { getOrganizations } from './redux/actions/organizations';

import AuthService from './services/auth.service';
import { getUserInfo } from './api/users';
import { OAuthSuccessEvent, OAuthErrorEvent } from './services/auth.event';
import NavbarContainer from './containers/NavbarContainer/NavbarContainer';
import { routes, PrivateRoute } from './routes';

import { authConfig } from './auth.config';

import './styles/App.css';
import translations from './i18n/translations.json';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticationIsDone: false
    };

    this.props.initialize({
      languages: ['fr', 'en'],
      transaltion: translations,
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true,
        defaultLanguage: 'fr'
      }
    });

    this.props.addTranslation(translations);
  }

  componentDidMount() {
    // OAuth2 service configuration.
    AuthService.configure(authConfig);
    // Store token in browser session storage.
    AuthService.setStorage(sessionStorage);

    // Subscribe to OAuth2 service event.
    AuthService.events.subscribe(event => {
      event instanceof OAuthErrorEvent
        ? console.error(event)
        // eslint-disable-next-line no-console
        : console.debug(event);
    });

    // Check access token is received.
    AuthService.events
      .pipe(filter((e: OAuthSuccessEvent) => e.type === 'token_received'))
      .subscribe(() => {
        // Update user info.
        getUserInfo().then((user => {
          AuthService.setUserInfo(user);
          this.props.authenticating();
          this.props.setUser(user);
        }));
      });

    // Check OAuth 2.0 token access.
    return AuthService.tryLogin()
      .then(() => {
        // Check access token validity.
        if (!AuthService.hasValidAccessToken()) {
          // Go to authentication provider login page.
          AuthService.getAuthentication();
        } else {
          AuthService.setAuthentication();
          this.setState({
            authenticationIsDone: true
          })
        }
      }).catch(err => {
        console.error(err);
      });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.isAuthenticated !== this.props.isAuthenticated && this.props.isAuthenticated) {
      const referentials = [
        this.props.getCategories,
        this.props.getFormats,
        this.props.getTrainingTypes,
        this.props.getTrainingStatus,
        this.props.getFundingProviders,
        this.props.getOrganizations
      ];

      Promise.all(referentials.map(referential => {
        try {
          return referential();
        }
        catch (err) {
          console.error(err);
        }
        // this will be reach only if we catch an error --- eslint need it.
        return null;
       }));
    }
  }

  render() {
    // Return nothing will authentication is not done
    if (!this.state.authenticationIsDone) {
      return null;
    }

    // Define authenticated routes.
    const routeComponents = routes.map(({ path, component, isExact }, key) => (
      <PrivateRoute
        exact={isExact}
        path={path}
        component={component}
        key={key}
        isAuthenticated={this.props.isAuthenticated}
      />
    ));

    // Return a default path.
    const locationPath = pathname => {
      return pathname === '/' ? '/sessions-list' : pathname;
    };

    return (
      <div className="toolbar-bg-color row no-gutters">
        <div className="col-sm-12">
          <NavbarContainer
            pathname={locationPath(this.props.location.pathname)}
            history={this.props.history}
          />
          <Switch>
            {routeComponents}
          </Switch>
        </div>
      </div>
    );
  }
}

App.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
  authenticating: PropTypes.func,
  setUser: PropTypes.func,
  getCategories: PropTypes.func,
  getFormats: PropTypes.func,
  getTrainingTypes: PropTypes.func,
  getTrainingStatus: PropTypes.func,
  getFundingProviders: PropTypes.func,
  getOrganizations: PropTypes.func,
  initialize: PropTypes.func,
  addTranslation: PropTypes.func
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.user,
  categories: state.categories,
  formats: state.formats,
  trainingTypes: state.trainingTypes,
  trainingStatus: state.trainingStatus,
  fundingProviders: state.fundingProviders,
  organizations: state.organizations,
  translate: getTranslate(state.localize)
});

const mapDispatchToProps = dispatch => {
  return {
    authenticating: () => {
      dispatch(loggingSuccess());
    },
    setUser: user => {
      dispatch(setUserSuccess(user));
    },
    getCategories: () => {
      dispatch(getCategories());
    },
    getFormats: () => {
      dispatch(getFormats());
    },
    getTrainingTypes: () => {
      dispatch(getTrainingTypes());
    },
    getTrainingStatus: () => {
      dispatch(getTrainingStatus());
    },
    getFundingProviders: () => {
      dispatch(getFundingProviders());
    },
    getOrganizations: () => {
      dispatch(getOrganizations());
    }
  };
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
