export class AuthConfig {
    /**
     * @param json the custom configuration file.
     */
    constructor(json) {
        // Url of the Identity Provider.
        this.loginUrl = '';

        // URL of the SPA to redirect the user to after login.
        this.redirectUri = '';

        // The SPA's id. The SPA is registered with this id at the auth-server.
        this.clientId = '';

        // set the scope for the permissions the client should request.
        this.scope = '';

        // The response token type.
        this.responseType = '';

        if (json) {
            Object.assign(this, json);
        }
    }
};