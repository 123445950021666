/**
 * Return an object of type {day, time, duration} from a string date.
 * @param {string} param date to parse.
 */
export const parseDate = param => {
  var date = new Date(param);

  var day = date.toLocaleDateString('fr-FR');
  var time = date.toLocaleTimeString('fr-FR', {
    hour: 'numeric',
    minute: 'numeric'
  });

  var duration = parseDuration(date.duree);

  var obj = { day: day, time: time, duration: duration };

  return obj;
};

/**
 * @deprecated use instead parseTime and apply a prefered format.
 * @param {number} param value at number format.
 */
export const parseDuration = param => {
  let hour = Math.floor(param);
  let minutes = (param - Math.floor(param)) * 60;
  var duration = hour
    ? minutes
      ? hour + 'h' + minutes
      : hour + 'h'
    : minutes + 'min';

  return duration;
};

/**
 * Return an object of type {hour, minutes} from a number.
 * @param {number} param value at number format
 */
export const parseTime = param => {
  let hour = Math.floor(param);
  let minutes = Math.round(((param - hour) * 100 / 100) * 60);

  return { hour: hour, minutes: minutes };
};

/**
 * Return the closest date to today from the array.
 * @param {array} arr an array of dates / object with date property.
 * @param {string} prop property name of the date container (if need).
 */
export const getClosestDateToToday = (arr, prop) => {
  const now = new Date();
  //now.setHours(23,59,59);
  return arr.reduce((a, b) => {
    let aDate, bDate;
    if (prop && a.hasOwnProperty(prop)) aDate = a[prop];
    if (prop && b.hasOwnProperty(prop)) bDate = b[prop];
    aDate = new Date(aDate);
    bDate = new Date(bDate);
    return Math.abs(aDate - now) < Math.abs(bDate - now) ? a : b;
  });
};
