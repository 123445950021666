import * as types from './actionTypes';
import ApiCall from '../../api/api';
import { endpoints } from '../../api/endpoints';

export const getTrainingTypesSuccess = trainingTypes => ({
  type: types.GET_TRAINING_TYPES_SUCCESS,
  trainingTypes
});

export function getTrainingTypes() {
  return dispatch => {
    return ApiCall.get(endpoints().GETTRAININGTYPES)
      .then(response => {
        dispatch(getTrainingTypesSuccess(response.data));
      })
      .catch(error => {
        throw error;
      });
  };
}
