import React from 'react';
import { Translate } from 'react-localize-redux';
import { Icon, Icons } from '../../shared/Icons/Index';

const TrainingEmpty = () => (
    <div className="training-card training-card-missing rounded p-2">
        <div className="d-flex flex-column align-items-center mt-4">
            <Icon icon={Icons.SADFACE} className="icon-size-big"/>
            <p style={{textAlign:'center'}}><Translate id="trainingCard.noValues" /></p>
        </div>
    </div>
  )

  export default TrainingEmpty;