import ApiCall from './api';
import { endpoints } from './endpoints';

export const updateSubscriberStatus = data => {
  let requestData = [];
  requestData.push(data.id);
  requestData.push(data.statutInscription.id);

  return new Promise((resolved, reject) => {
    ApiCall.put(endpoints(data.id).PUTSUBSCRIBERSTATUS, requestData)
      .then(response => {
        resolved(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const addSubscriber = inscription => {
  return new Promise((resolved, reject) => {
    ApiCall.post(endpoints().ADDSUBSCRIBER, inscription)
      .then(response => {
        resolved(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
