import axios from 'axios';
import { isNullOrUndefined } from '../utils/checking';
import qs from 'qs';

// Axios configuration.
axios.defaults.xsrfHeaderName = 'X-CSRF-TOKEN';
axios.defaults.xsrfCookieName = 'CSRF-TOKEN';
axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-CSRF-TOKEN'] = document.cookie.split('=')[1];

/**
 * Exposed all relative training status API access.
 */
class ApiCall {
  /**
   * Get data.
   * @param {string} endpoint API endpoint.
   */
  static get(endpoint, params) {
    if (typeof endpoint !== 'string') {
      throw new Error('Specify a string endpoint URL.');
    }

    if (isNullOrUndefined(params)) {
      return axios.get(endpoint);
    } else {
      return axios.get(endpoint, {
        params: {
          ...params
        },
        paramsSerializer: function(params) {
          return qs.stringify(params, { indices: false })
        }
      });
    }
  }

  /**
   * Put data.
   * @param {string} endpoint API endpoint.
   * @param {*} data Object data.
   */
  static put(endpoint, data) {
    if (typeof endpoint !== 'string') {
      throw new Error('Specify a string endpoint URL.');
    }

    if (isNullOrUndefined(data)) {
      throw new Error('Expected a data object.');
    }

    return axios.put(endpoint, data);
  }

  /**
   * Delete data.
   * @param {string} endpoint API endpoint.
   * @param {*} data Object data.
   */
  static delete(endpoint, data) {
    if (typeof endpoint !== 'string') {
      throw new Error('Specify a string endpoint URL.');
    }

    if (isNullOrUndefined(data)) {
      throw new Error('Expected a data object.');
    }

    return axios.delete(endpoint, data);
  }

  /**
   * Post data.
   * @param {string} endpoint API endpoint.
   * @param {*} data Object data.
   */
  static post(endpoint, data) {
    if (typeof endpoint !== 'string') {
      throw new Error('Specify a string endpoint URL.');
    }

    if (isNullOrUndefined(data)) {
      throw new Error('Expected a data object.');
    }

    return axios.post(endpoint, data);
  }
}

export default ApiCall;
