import { endpoints } from '../api/endpoints';
import Interceptors from '../api/interceptors';
import { Subject } from 'rxjs';
import { OAuthSuccessEvent, OAuthErrorEvent } from './auth.event';
import { AuthConfig } from './auth.config';

export const CURRENT_USER_SESSION_ATTRIBUTE_NAME = 'current_user';
export const BEARER_TOKEN_INTERCEPTOR_NAME = 'bearer_token';

/**
 * OAuth2 authentication class service.
 */
class AuthService extends AuthConfig {
  constructor(config, storage) {
    super();

    // Define default auth config.
    this.config = config;
    this.eventsSubject = new Subject();
    this.events = this.eventsSubject.asObservable();

    if (config) {
      this.configure(config);
    }

    // Define storage.
    try {
      if (storage) {
        this.setStorage(storage);
      } else if (typeof sessionStorage !== 'undefined') {
        this.setStorage(sessionStorage);
      }
    } catch (e) {
      console.error(
        'No OAuthStorage provided and cannot access default (sessionStorage).' +
          'Consider providing a custom OAuthStorage implementation in your module.',
        e
      );
    }
  }

  /**
   * Try to login implicitly a user to the application.
   * Check access token on storage
   */
  tryLogin() {
    const params = new URLSearchParams(window.location.hash.replace('#','?'));

    if (params.get('error')) {
      this.debug('error trying to login');
      window.location.hash = '';
      const err = new OAuthErrorEvent('token_error', {}, params);
      this.eventsSubject.next(err);
      return Promise.reject(err);
    }

    const accessToken = params.get('access_token');

    if (!accessToken) {
      return Promise.resolve(false);
    }

    if (accessToken) {
      this.storeAccessTokenResponse(
        accessToken,
        params.get('expires_in'),
        params.get('scope')
      );
      this.addInterceptor(this.createBearerToken(accessToken));
      this.eventsSubject.next(
        new OAuthSuccessEvent('token_received', {
          accessToken: accessToken
        })
      );
      window.location.hash = '';
    }

    return Promise.resolve(true);
  }

  // Go to Authentication Provider Login Page.
  getAuthentication() {
    const seperationChar = this.config.loginUrl.indexOf('?') > -1 ? '&' : '?';
    const url =
      this.config.loginUrl +
      seperationChar +
      'response_type=' +
      encodeURIComponent(this.config.responseType) +
      '&client_id=' +
      encodeURIComponent(this.config.clientId) +
      '&redirect_uri=' +
      encodeURIComponent(this.config.redirectUri) +
      '&scope=' +
      encodeURIComponent(this.config.scope);
    window.location.href = url;
  }

    // Enable OAuth authentication.
    setAuthentication() {
      this.addInterceptor(this.createBearerToken(this._storage.getItem("access_token")));
    }

  // Check if current user is authenticate.
  isUserLoggedIn() {
    let user = this._storage.getItem(CURRENT_USER_SESSION_ATTRIBUTE_NAME);
    if (user === null) return false;
    return true;
  }

  // Remove session info and interceptor.
  logout() {
    this._storage.removeItem(CURRENT_USER_SESSION_ATTRIBUTE_NAME);
    this._storage.removeItem('access_token');
    this._storage.removeItem('access_token_stored_at');
    this._storage.removeItem('expires_at');
    this._storage.removeItem('granted_scopes');
    this.removeInterceptor();
    window.location.href = endpoints().LOGOUT;
  }

  /**
   * Save authenticated user info to storage.
   * @param {object} user authorised user object.
   */
  setUserInfo(user) {
    this._storage.setItem(CURRENT_USER_SESSION_ATTRIBUTE_NAME, JSON.stringify(user));
  }

  // Get authenticated user info from storage.
  getUserInfo() {
    let user = JSON.parse(this._storage.getItem(CURRENT_USER_SESSION_ATTRIBUTE_NAME));
    if (user === null) return '';
    return user;
  }

  /**
   * Add a new request interceptor.
   * @param {string} token the access token.
   */
  addInterceptor(token) {
    Interceptors.addRequestInterceptor(
      BEARER_TOKEN_INTERCEPTOR_NAME,
      config => {
        config.headers.authorization = token;
        return config;
      }
    );
  }

  // Delete interceptor.
  removeInterceptor() {
    Interceptors.removeInterceptor(BEARER_TOKEN_INTERCEPTOR_NAME);
  }

  /**
   * Checkes, whether there is a valid access_token.
   * @return {boolean}
   */
  hasValidAccessToken() {
    if (this.getAccessToken()) {
      var expiresAt = this._storage.getItem('expires_at');
      var now = new Date();
      if (expiresAt && parseInt(expiresAt, 10) < now.getTime()) {
        return false;
      }
      return true;
    }
    return false;
  }

  /**
   * Returns the current access_token.
   * @return {string} the access token.
   */
  getAccessToken() {
    return this._storage ? this._storage.getItem('access_token') : null;
  }

  /**
   * Save authentication access response to storage.
   * @param {string} accessToken
   * @param {string} expiresIn
   * @param {string} grantedScopes
   */
  storeAccessTokenResponse(accessToken, expiresIn, grantedScopes) {
    this._storage.setItem('access_token', accessToken);
    if (grantedScopes) {
      this._storage.setItem(
        'granted_scopes',
        JSON.stringify(grantedScopes.split('+'))
      );
    }
    this._storage.setItem('access_token_stored_at', '' + Date.now());
    if (expiresIn) {
      /** @type {?} */
      var expiresInMilliSeconds = expiresIn * 1000;
      /** @type {?} */
      var now = new Date();
      /** @type {?} */
      var expiresAt = now.getTime() + expiresInMilliSeconds;
      this._storage.setItem('expires_at', '' + expiresAt);
    }
  }

  /**
   * Create a Bearer Token.
   *  @param {string} token The access token.
   */
  createBearerToken(token) {
    return 'Bearer ' + token;
  }

  /**
   * Use this method to configure the service
   * @param {?} config the configuration
   * @return {?}
   */
  configure(config) {
    // For the sake of downward compatibility with
    // original configuration API
    Object.assign(this, new AuthConfig(), config);
    this.config = Object.assign({}, new AuthConfig(), config);
  }

  /**
   * Define the local storage.
   * @param {object} storage
   * @return {void}
   */
  setStorage(storage) {
    this._storage = storage;
  }

  /**
   * Return access token url parameters string for authentication.
   * @return {string} the authentication URL params.
   */
  getAccessTokenUrl() {
    const accessToken = this.getAccessToken();
    const storedAt = parseInt(this._storage.getItem('access_token_stored_at'), 10);
    const expiresInMilliSeconds = parseInt(this._storage.getItem('expires_at'), 10) - storedAt;
    const expiresIn = expiresInMilliSeconds / 1000;
    return `#access_token=${accessToken}&token_type=bearer&expires_in=${expiresIn}`;
  }
}

export default new AuthService();
