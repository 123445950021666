import * as types from './actionTypes';

export const setUserSuccess = user => ({
  type: types.SET_USER_SUCCESS,
  user
});

export const resetUserSuccess = () => ({
  type: types.RESET_USER_SUCCESS
});
