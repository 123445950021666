import React from 'react';
import PropTypes from 'prop-types';

import { Editor } from 'react-draft-wysiwyg';

import { Translate } from 'react-localize-redux';

import { Icons } from '../../shared/Icons/Index';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../../styles/components/layers/ContactSubscribers/ContactSubscribers.css';
import { SubmitButton } from '../../shared/Buttons/Index';

import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';

const ContactSubscribers = props => {
  let formEl = null;

  const uploadImageCallBack = file => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', 'https://api.imgur.com/3/image');
      xhr.setRequestHeader('Authorization', 'Client-ID 8d26ccd12712fca');
      const data = new FormData();
      data.append('image', file);
      xhr.send(data);
      xhr.addEventListener('load', () => {
        const response = JSON.parse(xhr.responseText);
        resolve(response);
      });
      xhr.addEventListener('error', () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    });
  };

  return (
    <form
      className="contactSubs pt-5 was-validated"
      ref={form => (formEl = form)}
      noValidate>
      <div className="d-flex align-items-center flex-fill block-element">
        <h2 className="mr-2">
          <Translate id="sessionDetailCard.selectRole" />
        </h2>
        <div className="flex-grow-1">
          <Select
            defaultValue={[props.listStatus[0]]}
            isMulti
            onChange={props.onSelectStatusChange}
            components={makeAnimated()}
            id="selectRoles"
            inputId="selectRoles"
            options={props.listStatus}
            className="basic-multi-select"
            classNamePrefix="select"
            required
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-fill block-element">
        <h2 className="mr-2">
          <Translate id="sessionDetailCard.objectMail" />
        </h2>
        <div className="flex-grow-1">
          <input
            type="text"
            name="subscriber-mail-object"
            className="form-control w-100 style-object"
            required
          />
        </div>
      </div>

      <Editor
        wrapperClassName="wrapper"
        editorClassName="editor form-control"
        toolbarClassName="toolbar"
        onContentStateChange={props.onContentStateChange}
        toolbar={{
          image: {
            urlEnabled: true,
            uploadEnabled: true,
            uploadCallback: uploadImageCallBack,
            previewImage: true,
            alignmentEnabled: true,
            alt: { present: true, mandatory: false }
          }
        }}
      />
      <SubmitButton
        icon={Icons.SAVE}
        label="sessionDetailCard.btnContactSubscribers"
        onClick={e => {
          e.preventDefault();
          props.onFormSubmit(formEl);
        }}
      />
    </form>
  );
};

ContactSubscribers.propTypes = {
  content: PropTypes.object,
  onContentStateChange: PropTypes.func,
  onSelectStatusChange: PropTypes.func,
  listStatus: PropTypes.array,
  onFormSubmit: PropTypes.func
};

export default ContactSubscribers;
