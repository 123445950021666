import ApiCall from './api';
import { endpoints } from './endpoints';

export const getUsers = async () => {
  return new Promise((resolved, reject) => {
    ApiCall.get(endpoints().GETUSERS, {sort:['lastModifiedDate,desc', 'createdDate,desc']})
      .then(response => {
        resolved(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getUserInfo = async () => {
  return new Promise((resolved, reject) => {
    ApiCall.get(endpoints().USERINFO)
      .then(response => {
        resolved(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}
