import React from 'react';
import PropTypes from 'prop-types';

import { isNullOrUndefined } from '../../../utils/checking';

export const Icon = props => {
  const styles = {
    svg: {
      display: 'inline-block',
      verticalAlign: 'middle'
    },
    path: {
      fill: props.color
    }
  };

  const getPath = () => {
    if (isNullOrUndefined(props.icon.path)) return;

    if (Array.isArray(props.icon.path))
      return props.icon.path.map((path, index) => {
        return <path key={index} style={styles.path} d={path} />;
      });
    else return <path style={styles.path} d={props.icon.path} />;
  };

  const getRect = () => {
    if (isNullOrUndefined(props.icon.rect)) return;

    if (Array.isArray(props.icon.rect))
      return props.icon.rect.map((rect, index) => {
        return (
          <rect
            key={index}
            x={rect.x}
            y={rect.y}
            width={rect.width}
            height={rect.height}
            transform={rect.transform}
          />
        );
      });
    else
      return (
        <rect
          x={props.icon.rect.x}
          y={props.icon.rect.y}
          width={props.icon.rect.width}
          height={props.icon.rect.height}
          transform={props.icon.rect.transform}
        />
      );
  };

  const getPolygon = () => {
    if (isNullOrUndefined(props.icon.polygon)) return;

    if (Array.isArray(props.icon.polygon))
      return props.icon.polygon.map((polygon, index) => {
        return <polygon key={index} points={polygon} />;
      });
    else return <polygon points={props.icon.polygon} />;
  }

  return (
    <svg
      style={styles.svg}
      width={`${props.size}px`}
      height={`${props.size}px`}
      viewBox={props.icon.viewBox || props.viewBox}
      onClick={props.onClick}
      className={props.className}>
      {props.title && <title>{props.title}</title>}
      {getPath()}
      {getRect()}
      {getPolygon()}
    </svg>
  );
};

Icon.propTypes = {
  icon: PropTypes.object.isRequired,
  size: PropTypes.number,
  viewBox: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.any,
  title: PropTypes.string
};

Icon.defaultProps = {
  size: 16,
  viewBox: '0 0 1024 1024',
  color: 'currentColor'
};
