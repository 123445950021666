import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

import { Icons } from '../../shared/Icons/Index';
import subscriberStatus from '../../../enums/subscriberStatus';
import { ClickButton } from '../../shared/Buttons/Index';

const TableTemplate = props => {
  let cols = props.cols;
  const rows = props.subscribers;

  const head = (
    <thead>
      <tr>
        {(props.onClickEdit) && (
          <th />
        )}
        {Array.isArray(cols) &&
          cols.map((col, colIndex) => <th key={colIndex}><Translate id={col.title} /></th>)}
      </tr>
    </thead>
  );

  const body = rows.map((row, rowIndex) => (
    <tr key={rowIndex} className={`row-${rowIndex}`}>
      {props.onClickEdit && (
        <td>
          <ClickButton
            icon={Icons.EDIT}
            iconOnly
            className="icon-size-medium clickable"
            label="trainingDetail.enableEdit"
            onClick={e => props.onClickEdit(e, row)} />
        </td>
      )}
      {cols.map((col, colIndex) => (
        <td
          className="align-middle"
          key={colIndex}
          title={typeof row[col.field] === 'string' ? row[col.field] : ''}>
          {props.editMode ? (
            col.field === 'status' ? (
              <select
                className="form-control"
                defaultValue={row[col.field]}
                onChange={e => props.onChangeSubscriberStatus(e, row['id'])}>
                {subscriberStatus.getEnums().map(([key, value]) => (
                  <option key={key}>{value}</option>
                ))}
              </select>
            ) : (
              row[col.field]
            )
          ) : (
            row[col.field]
          )}
        </td>
      ))}
    </tr>
  ));

  // Decorate with Bootstrap CSS
  return (
    <div className="table-responsive administration">
      <table className={`${props.className} table table-sm table-hover`}>
        {head}
        <tbody>{body}</tbody>
      </table>
    </div>
  );
};

TableTemplate.propTypes = {
  cols: PropTypes.array,
  className: PropTypes.any,
  subscribers: PropTypes.array,
  editMode: PropTypes.bool,
  onChangeSubscriberStatus: PropTypes.func,
  onClickEdit: PropTypes.func
};

export default TableTemplate;
