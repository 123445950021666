export const trainingStatusEnum = {
  ENABLE: 'Actif',
  DISABLE: 'Inactif'
};

const colorStatusEnum = {
  [trainingStatusEnum.ENABLE]: 'status-color-enable',
  [trainingStatusEnum.DISABLE]: 'status-color-disable'
};

/**
 * Provide all methods relating to the use of the status enumeration.
 */
class trainingStatus {
  /**
   * Return HTML class color of training status.
   */
  static getColor = status => {
    return colorStatusEnum[status];
  };

  /**
   * Parse status enumaration in an array object of [key, value].
   */
  static getEnums = () => {
    return Object.entries(trainingStatusEnum);
  };
}

export default trainingStatus;
