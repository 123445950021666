import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import SessionContainer from './containers/SessionContainer/SessionContainer';
import TrainingListContainer from './containers/TrainingListContainer/TrainingListContainer';
import SessionListContainer from './containers/SessionListContainer/SessionListContainer';

export const routes = [
  {
    path: '/',
    component: TrainingListContainer,
    isExact: true
  },
  {
    path: '/training-list',
    component: TrainingListContainer,
    isExact: false
  },
  {
    path: '/sessions-list',
    component: SessionListContainer,
    isExact: true
  },
  {
    path: '/sessions-list/detail/(edit|new)/:id?',
    component: SessionContainer,
    isExact: false
  },
  {
    path: '/sessions-list-scheduled/:id?',
    component: SessionListContainer,
    isExact: false
  }
];

export const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  ...otherProps
}) => (
  <Route
    {...otherProps}
    render={props =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.any,
  isAuthenticated: PropTypes.bool
};
