import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import SessionListSideBarContainer from '../SessionListSideBarContainer/SessionListSideBarContainer';
import SessionCard from '../../components/layers/Sessions/SessionCard';
import SessionEmpty from '../../components/layers/Sessions/SessionEmpty';
import { endpoints } from '../../api/endpoints';
import ApiCall from '../../api/api';
import { sessionStatusEnum } from '../../enums/sessionStatus';
import roles, { roleEnum } from '../../enums/roles';
import sessionStatus from '../../enums/sessionStatus';
import Loading from '../../components/shared/Loading/Loading';

import { getSessions, getScheduledSessions } from '../../api/sessions';

import getAttendanceSheet from '../../utils/attendanceSheet';
import { isNullOrUndefined } from 'util';
import { getClosestDateToToday } from '../../utils/parseDate';

const compareByStatus = (a, b) => {
  const statutA = sessionStatus.getIndex()[a.props.session.statut];
  const statutB = sessionStatus.getIndex()[b.props.session.statut];
  if (statutA < statutB)
    return -1;
  if (statutA > statutB)
    return 1;
  return 0;
}

class SessionListContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickAttendance = this.handleClickAttendance.bind(this);
    this.handleSubscription = this.handleSubscription.bind(this);
    this.handleUnsubscribe = this.handleUnsubscribe.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);

    this.state = {
      sessions: [],
      showList: false,
      subscription: [],
      selectedStatus: 'all'
    };
  }

  async componentDidMount() {
    this.displayList(this.props.match.params.id);
    this.getSubscription();
  }

  componentDidUpdate(prevProps, prevState) {
    ReactTooltip.rebuild();
    if (prevState.hidden !== this.state.hidden) {
      // The tooltips need to be rebuilt because either the relative component
      // is now being rendered.
      ReactTooltip.rebuild();
    }
  }

  async getSubscription() {
    let response = await ApiCall.get(
      endpoints().SESSIONSUBSCRIPTION + '?page=0&size=500&sort=id,asc'
    );
    let session_sub = [];

    response.data.forEach(inscription => {
      if (inscription.id != null) session_sub.push(inscription);
    });
    this.setState({
      subscription: session_sub
    });
  }

  displayList(id) {
    if (isNullOrUndefined(id)) {
      getSessions()
        .then(response => {
          this.setState({
            sessions: response.data,
            showList: true
          });
        })
        .catch(e => console.error('error :', e));
    } else {
      getScheduledSessions(id)
        .then(response => {
          this.setState({
            sessions: response.data,
            showList: true
          });
        })
        .catch(e => console.error('error :', e));
    }
  }

  fillCardData = Session => {
    let datesSessionsFormatees = this.formateDatesAndGetDuration(
      Session.planifications
    );
    let dates = datesSessionsFormatees.dates;
    let totalduration = datesSessionsFormatees.totalduration;

    return {
      id: Session.sessionId,
      intitule: Session.formation.intitule,
      formateur: Session.formateur,
      commentaire: Session.commentaire,
      totalduration: totalduration,
      statut: Session.statutSession.libelle,
      dates: dates,
      lieu: Session.lieu,
      nbmax: Session.nbMax,
      numsub: Session.nb_sub,
      category: Session.formation.categorie,
      rating: Session.rating
    };
  };

  fromHoursToDurationFormat(hours) {
    let hour = Math.floor(hours);
    let minutes = Math.round((hours - Math.floor(hours)) * 60);

    let duration = {
      Hours: hour,
      Minutes: minutes
    };

    return duration;
  }

  formateDatesAndGetDuration = data => {
    var dates = [];
    var totalduration = 0;

    data.forEach(p => {
      totalduration = totalduration + p.duree;
      let date = p.dateSession;

      let obj = {
        id: p.id,
        date: date,
        duration: this.fromHoursToDurationFormat(p.duree)
      };

      dates.push(obj);
    });

    return {
      dates: dates,
      totalduration: this.fromHoursToDurationFormat(totalduration)
    };
  };

  /**
   * Filter selected categories / display all categories.
   * @param {*} currentCategory category object
   */
  isSelectedCategory(currentCategory) {
    const selectedCategories = this.props.categories.filter(
      category => category.isSelected
    );
    const hasCategories = selectedCategories.some(
      category => currentCategory.libelle === category.name
    );

    return (
      (selectedCategories.length > 0 && hasCategories) ||
      selectedCategories.length === 0
    );
  }

  /**
   * Filter sessions by status
   * @param {string} currentStatus session status
   */
  isSelectedStatus(currentStatus) {
    if (this.state.selectedStatus === 'all') return true;
    return currentStatus === this.state.selectedStatus;
  }

  handleChangeStatus = e => {
    this.setState({ selectedStatus: e.target.value });
  };

  /**
   * Download attendance checklist.
   * @param {object} session session object.
   */
  handleClickAttendance(e, session, translation) {
    e.preventDefault();
    const prop = 'date';
    const date = getClosestDateToToday(session.dates, prop);
    getAttendanceSheet(
      session.id,
      session.intitule,
      session.formateur,
      date[prop],
      translation
    );
  }

  async handleSubscription(id) {
    try {
      let response = await ApiCall.post(endpoints().SESSIONSUBSCRIPTION, [id]);
      let subs = this.state.subscription.slice();
      let sessions = this.state.sessions.slice();

      subs.push(response.data);
      sessions[
        sessions.findIndex(session => session.sessionId === id)
      ].nb_sub += 1;

      this.setState({
        subscription: subs,
        sessions: sessions
      });
    } catch (e) {
      console.error(e);
    }
  }

  async handleUnsubscribe(unsub_session) {
    try {
      let index = this.state.subscription.findIndex(sub => {
        return sub.sessionFormation.id === unsub_session.id;
      });

      await ApiCall.put(
        endpoints().DELETESUBSCRIPTION + this.state.subscription[index].id,
        {}
      );

      let subscription = this.state.subscription.slice();
      let sessions = this.state.sessions.slice();

      subscription.splice(index, 1);
      sessions[
        sessions.findIndex(session => session.sessionId === unsub_session.id)
      ].nb_sub -= 1;

      this.setState({
        subscription: subscription
      });
    } catch (e) {
      console.error(e);
    }
  }

  displaySessions = () => {
    let sessions = this.state.sessions
      .filter(session =>
          this.isSelectedCategory(session.formation.categorie) &&
          this.isSelectedStatus(session.statutSession.libelle)
      )
      .filter(session => {
        if (roles.hasAbility(this.props.user, roleEnum.ADMIN))
          return true;
        return session.statutSession.libelle === sessionStatusEnum.OPEN;
      })
      .filter(session => {
        if (roles.hasAbility(this.props.user, roleEnum.ADMIN))
          return true;
        const now = new Date();
        return new Date(session.dateSession) >= now;
      })
      .map((session, index) => {
        const isRegistered = this.state.subscription.some(
          sub =>
            sub.sessionFormation &&
            sub.sessionFormation.id === session.sessionId
        );

        return (
          <SessionCard
            key={index}
            registered={isRegistered}
            session={this.fillCardData(session)}
            subscription={this.state.subscription}
            onHandleClickAttendance={this.handleClickAttendance}
            onSubscription={this.handleSubscription}
            onUnsubscription={this.handleUnsubscribe}
            onClickAttendance={this.handleClickAttendance}
          />
        )
      })
      .sort(compareByStatus);

    // Display no sessions template.
    if (sessions.length === 0) {
      sessions = <SessionEmpty />
    }

    return sessions;
  }

  render() {
    return (
      <div className="d-flex flex-column flex-md-row justify-content-center mt-2">
        <div className="sidebar-element mb-2 mr-md-2 mb-md-0">
          <SessionListSideBarContainer
            onChangeStatus={this.handleChangeStatus}
          />
        </div>
        <div className="content-element">
          <ReactTooltip
            id="commentTip"
            place="bottom"
            type="dark"
            effect="float"
            className="preline"
          />
          {this.state.showList && this.displaySessions()}
          {!this.state.showList && <Loading />}
        </div>
      </div>
    );
  }
}

SessionListContainer.propTypes = {
  user: PropTypes.object,
  categories: PropTypes.array.isRequired,
  match: PropTypes.object
};

const mapStateToProps = state => ({
  user: state.user,
  categories: state.categories
});

export default connect(mapStateToProps)(SessionListContainer);
