import * as types from '../actions/actionTypes';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TRAININGS_SUCCESS:
      return action.trainings || [];
    case types.ADD_TRAINING_SUCCESS:
      return { ...state, ...action.training };
    case types.DELETE_TRAINING_SUCCESS:
      return state.filter(training => training.id !== action.id)
    case types.UPDATE_TRAINING_SUCCESS:
      return [
        ...state.filter(training => training.id !== action.training.id),
        Object.assign({}, action.training)
      ];
    default:
      return state;
  }
};
