import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { endpoints } from '../api/endpoints';
import ApiCall from '../api/api';
import { subscriberStatusEnum } from '../enums/subscriberStatus';

/**
 * Download attendance checklist.
 * @param {object} session session object.
 */
export const getAttendanceSheet = (id, training, former, date, translation) => {
  ApiCall.get(endpoints(id).GETINSCRIPTIONSESSIONDETAIL).then(response => {
    var doc = new jsPDF('portrait', 'mm', 'a4');
    doc.setFontStyle('bold');
    doc.setFontSize(16);
    doc.text(
      10,
      20,
      `${translation[0]}: ${new Date(date).toLocaleDateString()}`
    );
    doc.setFontStyle('normal');
    doc.setFontSize(14);
    var text = `${translation[1]}: ${training}\n${translation[2]}: ${former}`;
    doc.text(10, 30, text);

    var columns = [translation[3], translation[4], translation[5]];
    var rows = response.data
      .filter(subscriber => subscriber.inscription.statutInscription.libelle === subscriberStatusEnum.REGISTER)
      .map(e => {
      return [e.user.firstName, e.user.lastName, ''];
    });

    doc.autoTable(columns, rows, {
      startY: 50,
      margin: {
        horizontal: 10
      },
      styles: {
        cellPadding: 5,
        overflow: 'linebreak'
      },
      bodyStyles: {
        valign: 'middle'
      },
      columnStyles: {
        email: {
          columnWidth: 'wrap'
        }
      },
      theme: 'striped'
    });

    doc.save('attendance_session_liste.pdf');
  });
};

export default getAttendanceSheet;
