import * as types from '../actions/actionTypes';

const initialState = {
  isAuthenticated: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOGGING_SUCCESS:
      return { ...state, isAuthenticated: true };

    case types.LOGOUT_SUCCESS:
      return { ...state, isAuthenticated: false };

    default:
      return state;
  }
};
