export const subscriberStatusEnum = {
  REGISTER: 'Inscrit',
  UNREGISTER: "Liste d'attente",
  PRESENT: 'Présent',
  RATED: 'Evaluée',
  UNPRESENT: 'Non présent',
  CANCEL: 'Annulée'
};

const subscriberStatusIndex = {
  [subscriberStatusEnum.REGISTER]: 1,
  [subscriberStatusEnum.UNREGISTER]: 2,
  [subscriberStatusEnum.PRESENT]: 3,
  [subscriberStatusEnum.RATED]: 4,
  [subscriberStatusEnum.UNPRESENT]: 5,
  [subscriberStatusEnum.CANCEL]: 6
};

/**
 * Provide all methods relating to the use of the status enumeration.
 */
class subscriberStatus {
  /**
   * Parse status enumaration in an array object of [key, value].
   */
  static getEnums = () => {
    return Object.entries(subscriberStatusEnum);
  };

  /**
   * Parse status enumeration in order to retrieve the appropriate index
   */
  static getIndex = () => subscriberStatusIndex;
}

export default subscriberStatus;
