export const endpoints = (id) => ({
  ADDSUBSCRIBER: '/api/inscriptions',
  DEACTIVE_USER: '/api/user-management/desactivate',
  DELETETRAINING: `/api/formations/${id}`,
  DELETEINSCRIPTIONS: `/api/inscriptions-session/${id}`,
  DELETESESSION: `/api/session-formations/${id}`,
  GETACCOUNT: '/api/account',
  GETALLSESSIONS: '/api/all-session-calculated-values',
  GETAUTHENTICATION: '/api/authentication',
  GETCATEGORIES: '/api/categories',
  GETFORMATS: '/api/formats',
  GETFUNDINGPROVIDERS: '/api/financements',
  GETORGANIZATIONS: '/api/organismes',
  GETSCHEDULEDSESSIONS: `/api/session-formations-open/${id}`,
  GETTRAINING: `/api/formations/${id}`,
  GETTRAININGS: '/api/formations',
  GETACTIVETRAININGS: '/api/98ahi4a',
  GETTRAININGSTATUS: '/api/statuts',
  GETTRAININGTYPES: '/api/type-formations',
  GETUSERS: '/api/users',
  POSTTRAINING: '/api/formations',
  PUTTRAINING: '/api/formations',
  PUTSUBSCRIBERSTATUS: `/api/inscription-session-detail/${id}`,
  USER_MANAGEMENT: '/api/users',
  UPLOADUSERLIST: 'api/upload-users',
  GETSESSIONBYID: `/api/qjf5z6?sessionId=${id}`,
  GETINSCRIPTIONSESSIONDETAIL: `/api/inscription-session-detail/${id}`,
  GETFINANCEMENT: '/api/financements/',
  EDITSESSION: '/api/session-formations/',
  EDITDATESESSION: '/api/planification/new/',
  MANAGERPLANIFICATION: '/api/planifications/',
  SESSIONSUBSCRIPTION: '/api/inscription-session/',
  DELETESUBSCRIPTION: '/api/inscription-session/delete/',
  PREVIOUSSESSION: '/api/inscriptions-passees',
  MAILSUBSCRIBERSESSION: '/api/session-formation/specific-mail',
  NOTIFICATIONOPENSESSION: '/api/session-formation-notify',
  INITRESETPASSWORD: '/api/account/reset_password/init',
  FINISHRESETPASSWORD:'/api/account/reset_password/finish',
  DEFAULTSESSIONADDRESS: '/api/defaultSession',
  LOGINURL: '/authentication-provider/oauth/authorize',
  USERINFO: '/authentication-provider/user/me',
  ACCESSTOKEN: '/authentication-provider/oauth/token',
  LOGOUT: '/authentication-provider/logout',
  GETNAVINFO: '/routing-service/api/paths'
});
