import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';

import { Icon } from '../../shared/Icons/Index';

export const LinkButton = props => {
  return (
    <Translate>
      {({ translate }) => (
        <Link
          to={props.path}
          className={props.iconOnly ? '' : props.className}
          title={props.label && translate(props.label)}>
          {props.icon && <Icon icon={props.icon} className={props.iconOnly ? props.className : 'icon-pictogram'} />}
          {(props.label && !props.iconOnly) && (
            <span>{translate(props.label)}</span>
          )}
        </Link>
      )}
    </Translate>
  );
};

LinkButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.object,
  path: PropTypes.string,
  className: PropTypes.any,
  iconOnly: PropTypes.bool
};

LinkButton.defaultProps = {
  path: '',
  className: ''
};
