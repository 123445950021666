import { endpoints } from "./api/endpoints";

export const authConfig = {
    // Url of the Identity Provider.
    loginUrl: endpoints().LOGINURL,

    // URL of the SPA to redirect the user to after login.
    redirectUri: window.location.origin,

    // The SPA's id. The SPA is registered with this id at the auth-server.
    clientId: 'ingeniance-client-1',

    // set the scope for the permissions the client should request.
    scope: 'ingeniance-portal',

    // The response token type.
    responseType: 'token'
  };