import * as types from './actionTypes';

export const loadAPIErrors = error => {
  return dispatch => {
    dispatch({
      type: types.API_ERROR,
      error
    });
  }
}

export const loadErrors = error => ({
  type: types.UI_ERROR,
  error
});

export const clearError = () => ({
  type: types.CLEAR_ERROR
});