import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';

import { FilterButton } from '../../shared/Buttons/Index';
import roles, { roleEnum } from '../../../enums/roles';
import { Icons } from '../../shared/Icons/Icons';
import { LinkButton, ClickButton } from '../../shared/Buttons/Index';

const TrainingListSideBar = props => {
  return (
    <div className="training-list">
      <h1 className="px-2 rounded-top">
        <Translate id="trainingList.heading" />
      </h1>
      <div className="block-element px-2 hidden">
        <nav>
          <ul>
            <li>
              <LinkButton icon={Icons.LIST} label="trainingList.mySessions" />
            </li>
            <li>
              <LinkButton icon={Icons.HISTORY} label="trainingList.history" />
            </li>
          </ul>
        </nav>
      </div>
      <div
        className={`block-element px-2 ${
          roles.hasAbility(props.user, roleEnum.ADMIN) ? '' : 'rounded-bottom'
        }`}>
        <h2>
          <Translate id="trainingList.categorySearchHeading" />
        </h2>
        {props.categories.map((category, index) => (
          <FilterButton
            key={index}
            category={category}
            onClick={props.onHandleClickCategory}
          />
        ))}
      </div>
      <div className={`block-element px-2 hidden`}>
        <h2>
          <Translate id="trainingList.classicSearchHeading" />
        </h2>
        <Translate>
          {({ translate }) => (
            <input
              className="form-control"
              type="text"
              placeholder={translate('trainingList.searchPlaceHolder')}
            />
          )}
        </Translate>
      </div>
      {roles.hasAbility(props.user, roleEnum.ADMIN) && (
        <div className="block-element px-2 rounded-bottom">
          <nav>
            <ul>
              <li>
                <ClickButton
                  icon={Icons.ADD}
                  label="trainingList.createTraining"
                  onClick={props.onHandleClickModalOpen}
                />
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

TrainingListSideBar.propTypes = {
  user: PropTypes.object,
  categories: PropTypes.array.isRequired,
  onHandleClickCategory: PropTypes.func,
  onHandleClickModalOpen: PropTypes.func
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(TrainingListSideBar);
