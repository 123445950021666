/* eslint-disable jsx-a11y/href-no-hash */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import { Translate } from 'react-localize-redux';

import roles, { roleEnum } from '../../../enums/roles';
import { FilterButton, LinkButton, ClickButton } from '../../shared/Buttons/Index';
import { Icon, Icons } from '../../shared/Icons/Index';
import trainingStatus from '../../../enums/trainingStatus';

import '../../../styles/components/layers/Trainings/TrainingCard.css';

const ProgramIcon = () => {
  const tooltip = renderToStaticMarkup(
    <Translate id="trainingCard.programTooltip" />
  );
  return (
    <div
      className="d-flex align-items-center"
      data-for="programTip"
      data-tip={tooltip}
      data-html>
      <Icon
        icon={Icons.PROGRAM}
        className="icon-size-medium clickable"
        onClick={e => {
          e.preventDefault();
          // TODO: open program modal
        }}
      />
    </div>
  );
};

const ScheduledButton = props => {
  if (props.nbSession > 0){
    return <li>
      <LinkButton
        icon={Icons.SCHEDULEDSESSIONS}
        label="trainingCard.btnScheduledOverview"
        path={`/sessions-list-scheduled/${props.trainingId}`}
      />
    </li>
  } else {
    return <li><Translate id="trainingCard.noScheduledSession" /></li>
  }
}

const TrainingCard = props => (
  <div className={`training-card training-card-${props.data.trainingId} rounded p-2 mb-2`}>
    <div className="d-flex flex-column flex-md-row mb-1">
      <div className="d-flex mr-auto">
        <a
          href="#empty"
          onClick={e => {
            props.onHandleClickModalOpen(e, props.data.trainingId);
          }}>
          <span className="training-card-label">{props.data.title || ''}</span>
        </a>
      </div>
      <div className="d-flex ">
        <dl className="justify-content-around mr-2">
          {roles.hasAbility(props.user, roleEnum.ADMIN) && [
          <dt key={0}>
            <Translate id="trainingCard.status" />
          </dt>,
          <dd key={1} className={`status-color ${trainingStatus.getColor(props.data.status.text)}`}>
            {props.data.status.text}
          </dd>
          ]}
          <dt>
            <Translate id="trainingCard.duration" />
          </dt>
          <dd>
            <Translate
              id="trainingCard.durationFormat"
              data={{
                hour: props.data.duration.hour || '0',
                minute: props.data.duration.minutes || '00'
              }}
            />
          </dd>
        </dl>
      </div>
      {/*<ProgramIcon />*/}
    </div>
    <div className="training-card-detail pt-2">
      <span>{props.data.description}</span>
    </div>
    <div className="d-flex justify-content-end mb-1">
      <FilterButton
        category={{
          isSelected: props.data.category.isSelected,
          name: props.data.category.text
        }}
      />
    </div>
    <div className="toolbar-element">
      <nav>
        <ul className={`d-flex ${roles.hasAbility(props.user, roleEnum.ADMIN) ? "justify-content-around" : ""}`}>
          <ScheduledButton nbSession={props.data.nbSession} trainingId={props.data.trainingId} />
          {roles.hasAbility(props.user, roleEnum.ADMIN) && (
            <li>
              <LinkButton
                icon={Icons.ADD}
                label="trainingCard.btnCreateSession"
                path={`/sessions-list/detail/new/${props.data.trainingId}`}
              />
            </li>
          )}
          {(roles.hasAbility(props.user, roleEnum.ADMIN) && props.data.nbSession === 0) && (
            <li>
              <ClickButton
                icon={Icons.DELETE}
                label="trainingCard.btnDelete"
                onClick={e => {
                  e.preventDefault();
                  props.onDeleteTraining(props.data.trainingId);
                }} />
            </li>
          )}
        </ul>
      </nav>
    </div>
  </div>
);

ProgramIcon.propTypes = {
  tooltip: PropTypes.string
};

ScheduledButton.propTypes = {
  nbSession: PropTypes.number,
  trainingId: PropTypes.number
}

TrainingCard.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  user: PropTypes.object,
  onHandleClickModalOpen: PropTypes.func,
  onDeleteTraining: PropTypes.func
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(TrainingCard);
