export const API_ERROR = 'API_ERROR';
export const UI_ERROR = 'UI_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const ADD_TRAINING_SUCCESS = 'ADD_TRAINING_SUCCESS';
export const DELETE_TRAINING_SUCCESS = 'DELETE_TRAINING_SUCCESS';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_FORMATS_SUCCESS = 'GET_FORMATS_SUCCESS';
export const GET_FUNDING_PROVIDERS_SUCCESS = 'GET_FUNDING_PROVIDERS_SUCCESS';
export const GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS';
export const GET_TRAININGS_SUCCESS = 'GET_TRAINING_SUCCESS';
export const GET_TRAINING_TYPES_SUCCESS = 'GET_TRAINING_TYPES_SUCCESS';
export const GET_TRAINING_STATUS_SUCCESS = 'GET_TRAINING_STATUS_SUCCESS';
export const LOGGING_SUCCESS = 'LOGGING_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_TRAINING_SUCCESS = 'UPDATE_TRAINING_SUCCESS';
export const RESET_USER_SUCCESS = 'RESET_USER_SUCCESS';
export const GET_NAVIGATION_INFO_SUCCESS = 'GET_NAVIGATION_INFO_SUCCESS';
