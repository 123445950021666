import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';

import { Icons } from '../../shared/Icons/Index';
import roles, { roleEnum } from '../../../enums/roles';
import { FilterButton, SubmitButton, ClickButton } from '../../shared/Buttons/Index';
import {
  AreaInput,
  SelectInput,
  TimeInput,
  TextInput
} from '../../shared/Inputs/Index';
import trainingStatus from '../../../enums/trainingStatus';

import '../../../styles/components/layers/Trainings/TrainingDetailCard.css';
import { isNullOrUndefined } from '../../../utils/checking';

const trainingDetail = props => {
  let formEl = null;

  const formatDataSource = () =>
    props.formats.map(format => ({
      value: format.id.toString(),
      text: format.libelle
    }));
  const typeTrainingDataSource = () =>
    props.trainingTypes.map(type => ({
      value: type.id.toString(),
      text: type.libelle
    }));
  const trainingStatusDataSource = () =>
    props.trainingStatus.map(type => ({
      value: type.id.toString(),
      text: type.libelle
    }));
  const organizationsDataSource = () =>
    props.organizations.map(type => ({
      value: type.id.toString(),
      text: type.libelle
    }));
  const categoriesDataSource = () =>
    props.categories.map(category => ({
      value: category.id.toString(),
      text: category.name
    }));

  /**
   * Define a add form type.
   */
  const addMode = isNullOrUndefined(props.data.trainingId);

  /**
   * Define default values.
   */
  if (addMode) {
    props.data.format = formatDataSource()[0];
    props.data.type = typeTrainingDataSource()[0];
    props.data.status = trainingStatusDataSource()[0];
    props.data.provider = organizationsDataSource()[0];
    props.data.category = categoriesDataSource()[0];
  }

  return (
    <form
      ref={form => (formEl = form)}
      className="training-detail was-validated rounded p-2 mb-2"
      onSubmit={e => {
        props.onClickSave(e, formEl);
      }}
      noValidate>
      <div className="d-flex mb-1">
        <div className="training-detail-label d-flex align-items-center mr-auto">
          {props.editMode && (
            <span>
              <Translate id="trainingDetail.title" />
            </span>
          )}
          <TextInput
            name="title"
            editMode={props.editMode}
            value={props.data.title}
            onChange={props.onChange}
            required
          />
        </div>
        {(roles.hasAbility(props.user, roleEnum.ADMIN) &&
          !addMode && !props.editMode) && (
            <div className="d-flex align-items-center mr-4">
              <ClickButton
                icon={Icons.EDIT}
                className="icon-size-medium clickable"
                label="trainingDetail.enableEdit"
                iconOnly
                onClick={props.onToggleEdit} />
            </div>
          )}
      </div>
      <div className="d-flex mb-1 justify-content-md-start toolbar-element">
        <div className="w-100">
          <dl className="">
            <dt>
              <Translate id="trainingDetail.format" />
            </dt>
            <dd className="editable">
              <SelectInput
                name="format"
                className="initial"
                editMode={props.editMode}
                selectedValue={props.data.format}
                data={formatDataSource()}
                onChange={props.onChange}
              />
            </dd>
            <dt>
              <Translate id="trainingDetail.duration" />
            </dt>
            <dd className="editable">
              <TimeInput
                name="duration"
                className="duration w-100"
                editMode={props.editMode}
                value={props.data.duration}
                translateId="trainingDetail.durationFormat"
                onChange={props.onChange}
              />
            </dd>
            <dt>
              <Translate id="trainingDetail.trainingTypeTitle" />
            </dt>
            <dd className="editable">
              <SelectInput
                name="type"
                className="w-100"
                editMode={props.editMode}
                selectedValue={props.data.type}
                translateId="trainingDetail.trainingTypeLabel"
                data={typeTrainingDataSource()}
                onChange={props.onChange}
              />
            </dd>
            {roles.hasAbility(props.user, roleEnum.ADMIN) && (
              <dt>
                <Translate id="trainingDetail.status" />
              </dt>
            )}
            {roles.hasAbility(props.user, roleEnum.ADMIN) && (
              <dd
                className={`status-color ${!addMode &&
                  trainingStatus.getColor(props.data.status.text)}`}>
                <SelectInput
                  name="status"
                  className="w-100"
                  editMode={props.editMode}
                  selectedValue={props.data.status}
                  data={trainingStatusDataSource()}
                  onChange={props.onChange}
                />
              </dd>
            )}
          </dl>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <i className="text-right training-detail-provider">
          <SelectInput
            name="provider"
            className="ml-2"
            editMode={props.editMode}
            selectedValue={props.data.provider}
            data={organizationsDataSource()}
            translateId="trainingDetail.provideBy"
            onChange={props.onChange}
          />
        </i>
      </div>
      <div className="row col-sm-12 col-md-12 block-element no-gutters">
        <h2 className="col-xs-12 col-sm-2">
          <Translate id="trainingDetail.description" />
        </h2>
        <p className="col-xs-12 col-sm-10">
          <AreaInput
            name="description"
            className="description"
            editMode={props.editMode}
            value={props.data.description}
            onChange={props.onChange}
            required
          />
        </p>
      </div>
      <div className="row col-sm-12 col-md-12 block-element no-gutters">
        <h2 className="col-xs-12 col-sm-2">
          <Translate id="trainingDetail.plan" />
        </h2>
        <p className="col-xs-12 col-sm-10 preline">
          <AreaInput
            name="plan"
            className="plan"
            editMode={props.editMode}
            value={props.data.plan}
            onChange={props.onChange}
            required
          />
        </p>
      </div>
      <div className="row col-sm-12 col-md-12 block-element no-gutters">
        <h2 className="col-xs-12 col-sm-2">
          <Translate id="trainingDetail.skills" />
        </h2>
        <p className="col-xs-12 col-sm-10">
          <AreaInput
            name="skills"
            className="skills"
            editMode={props.editMode}
            value={props.data.skills}
            onChange={props.onChange}
          />
        </p>
      </div>
      <div className="row col-sm-12 col-md-12 block-element no-gutters">
        <h2 className="col-xs-12 col-sm-2">
          <Translate id="trainingDetail.certification" />
        </h2>
        <p className="col-xs-12 col-sm-10">
          <AreaInput
            name="certification"
            className="certification"
            editMode={props.editMode}
            value={props.data.certification}
            onChange={props.onChange}
          />
        </p>
      </div>
      <div className="row col-sm-12 col-md-12 block-element no-gutters">
        <h2 className="col-xs-12 col-sm-2">
          <Translate id="trainingDetail.category" />
        </h2>
        {((props.editMode || addMode) && (
          <SelectInput
            name="category"
            editMode={props.editMode}
            selectedValue={props.data.category}
            data={categoriesDataSource()}
            onChange={props.onChange}
          />
        )) || (
          <FilterButton
            category={{
              isSelected: false,
              name: props.data.category.text
            }}
          />
        )}
      </div>
      {roles.hasAbility(props.user, roleEnum.ADMIN) &&
        props.editMode && (
          <div className="toolbar-element">
            <nav>
              <ul className={`d-flex ${!addMode ? "justify-content-around" : ""}`}>
                <li>
                  <SubmitButton
                    icon={Icons.SAVE}
                    label="trainingDetail.btnSave"
                    onClick={e => {
                      props.onClickSave(e, formEl);
                    }}
                  />
                </li>
                {!addMode && (
                <li>
                  <ClickButton
                    icon={Icons.CANCEL}
                    className="icon-size-medium clickable"
                    label="trainingDetail.cancelEdit"
                    onClick={props.onToggleEdit} />
                </li>
                )}
              </ul>
            </nav>
          </div>
        )}
    </form>
  );
};

trainingDetail.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
  formats: PropTypes.array,
  trainingTypes: PropTypes.array,
  trainingStatus: PropTypes.array,
  organizations: PropTypes.array,
  categories: PropTypes.array,
  editMode: PropTypes.bool,
  onToggleEdit: PropTypes.func,
  onChange: PropTypes.func,
  onClickSave: PropTypes.func
};

const mapStateToProps = state => ({
  user: state.user,
  formats: state.formats,
  trainingTypes: state.trainingTypes,
  trainingStatus: state.trainingStatus,
  organizations: state.organizations,
  categories: state.categories
});

export default connect(mapStateToProps)(trainingDetail);
