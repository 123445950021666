import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { Icon, Icons } from '../../shared/Icons/Index';
import { sessionStatusEnum } from '../../../enums/sessionStatus';
import TableComponent from '../../shared/TableTemplate/TableTemplate';
import { ClickButton } from '../../shared/Buttons/Index';
import roles, { roleEnum } from '../../../enums/roles';

import subscriberStatus, {
  subscriberStatusEnum
} from '../../../enums/subscriberStatus';

import '../../../styles/components/layers/Sessions/SessionDetailCard.css';

const SessionDetailCard = props => {
  let nbSubscribers = 0;
  let subscribers = [];
  let addUserSelect = null;
  let addUserStatus = null;

  props.subscribers &&
    props.subscribers.forEach(s => {
      if (s.inscription.statutInscription.libelle !== subscriberStatusEnum.CANCEL)
        nbSubscribers++;

      subscribers.push({
        id: s.id,
        firstName: s.user.firstName,
        lastName: s.user.lastName.toUpperCase(),
        inscription: new Date(s.dateInsc).toLocaleDateString('fr-FR'),
        status: s.inscription.statutInscription.libelle,
        rating: s.inscription.evaluation ? (
          <Translate>
            {({ translate }) => [
              <Icon
                key={0}
                icon={Icons.MAIL}
                className="icon-size-medium"
                title={translate('sessionDetailCard.evalSend')}
              />,
              <Icon
                key={1}
                icon={Icons.CHECKMARK}
                className="icon-size-small"
                title={translate('sessionDetailCard.evalFill')}
              />
            ]}
          </Translate>
        ) : (
          ''
        )
      });
    });

  /**
   * Render the "save button" if editMode is true.
   */
  const saveButton = () => (
    <ClickButton
      label="sessionDetailCard.btnSave"
      icon={Icons.SAVE}
      onClick={e => {
        e.preventDefault();
        props.onSaveSessionSubscribers();
      }}
    />
  );

  /**
   * Render the "contact sunscribers button".
   */
  const contactSubscribersButton = () => (
    <ClickButton
      icon={Icons.MAIL}
      label="sessionDetailCard.btnContactSubscribers"
      onClick={e => {
        e.preventDefault();
        props.onContactSubscriber();
      }}
    />);

  /**
   * Get HTML render for toobar buttons.
   */
  const getToolbar = () => {
    let element = {
      [sessionStatusEnum.DRAFT]: (
        <ul className="d-flex">
          {props.editMode && (<li>{saveButton()}</li>)}
        </ul>
      ),
      [sessionStatusEnum.OPEN]: (
        <ul className={`d-flex ${!props.editMode ? "justify-content-around": ""}`}>
          {props.editMode && (<li>{saveButton()}</li>)}
          {!props.editMode && (<li>{contactSubscribersButton()}</li>)}
          {!props.editMode && props.subscribers &&
            props.subscribers.length > 0 && (
              <li>
                <Translate>
                  {({ translate }) => (
                    <ClickButton
                      icon={Icons.CHECKLIST}
                      label="sessionDetailCard.btnAttendantList"
                      onClick={e =>
                        props.onClickAttendance(
                          e,
                          props.session,
                          Object.values(
                            translate([
                              'attendanceSheet.title',
                              'attendanceSheet.training',
                              'attendanceSheet.former',
                              'attendanceSheet.firstname',
                              'attendanceSheet.lastname',
                              'attendanceSheet.attendance'
                            ])
                          )
                        )
                      }
                    />
                  )}
                </Translate>
              </li>
            )}
        </ul>
      ),
      default: (
        <ul className="d-flex">
          {props.editMode && (<li>{saveButton()}</li>)}
          {!props.editMode && (<li>{contactSubscribersButton()}</li>)}
        </ul>
      )
    };

    return element[props.session.statutSession.libelle] || element['default'];
  };

  /**
   *
   * @param {*} sessionStatus
   */
  const getLinkByStatus = () => {
    var element = {
      [sessionStatusEnum.OPEN]: (
        <li>
          <ClickButton
            label="sessionDetailCard.btnNotifyOpening"
            icon={Icons.NOTIFYOPENING}
            onClick={((e) => {e.preventDefault(); props.onOpenConfirmModal()})}
          />
        </li>
      )
      // ,
      // [sessionStatusEnum.RATING]: (
      //   <li>
      //     <ClickButton
      //       label="sessionDetailCard.btnEvalResend"
      //       icon={Icons.FORWARDEVAL}
      //     />
      //   </li>
      // )
      ,
      default: ''
    };

    return element[props.session.statutSession.libelle] || element['default'];
  };

  return (
    <div className="session-detail rounded p-2 mb-2">
      <div className="d-flex align-items-center mb-1 toolbar-element">
        <div className="d-flex mr-auto">
          <Translate>
            {({ translate }) => (
              <Icon
                icon={Icons.USER}
                className="icon-size-medium mr-1"
                title={translate('sessionDetailCard.nbSubcribers')}
              />
            )}
          </Translate>
          {nbSubscribers} / {props.session.nbMax}
        </div>
        {roles.hasAbility(props.user, roleEnum.ADMIN) && (
          <div className="d-flex align-items-center">
            <nav>
              <ul className="d-flex">
                {props.subscribers &&
                  getLinkByStatus()}
                {!props.createMode && (
                <li className="ml-3">
                  <ClickButton
                    icon={props.editMode ? Icons.CANCEL : Icons.EDIT}
                    iconOnly
                    className="icon-size-medium clickable"
                    label={props.editMode
                      ? 'sessionDetailCard.btnCancelEdit'
                      : 'sessionDetailCard.btnEdit'
                    }
                    onClick={props.onToggleEditMode} />
                </li>
                )}
              </ul>
            </nav>
          </div>
        )}
      </div>
      {
        <TableComponent
          className="session-detail-table"
          cols={[
            {field: 'lastName', title: 'sessionDetailCard.lastname'},
            {field: 'firstName', title: 'sessionDetailCard.firstname'},
            {field: 'inscription', title: 'sessionDetailCard.inscription'},
            {field: 'status', title: 'sessionDetailCard.status'},
            {field: 'rating', title: 'sessionDetailCard.rating'}
          ]}
          subscribers={subscribers}
          editMode={props.editMode}
          onChangeSubscriberStatus={props.onChangeSubscriberStatus}
        />
      }
      {props.editMode &&
        nbSubscribers < props.session.nbMax && (
          <div className="session-detail-subscribe d-flex mb-2">
            <ul>
              <li>
                <select
                  className="form-control"
                  name="addUser"
                  ref={input => (addUserSelect = input)}>
                  {props.users &&
                    props.users.filter(u => u.activated).map((u, index) => (
                      <option key={index} value={u.login}>
                        {u.firstName} {u.lastName}
                      </option>
                    ))}
                </select>
              </li>
              <li>
                <select
                  className="form-control"
                  defaultValue="Inscrit"
                  ref={input => (addUserStatus = input)}>
                  {subscriberStatus.getEnums().map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
              </li>
              <li>
                <ClickButton
                  label="sessionDetailCard.btnSubscribe"
                  icon={Icons.ADD}
                  onClick={e => {
                    e.preventDefault();
                    props.onAddSubscriber(
                      addUserSelect.value,
                      addUserStatus.value
                    );
                  }}
                />
              </li>
            </ul>
          </div>
        )}
        {(!props.createMode && roles.hasAbility(props.user, roleEnum.ADMIN)) && (
          <div className="toolbar-element">
            <nav>{getToolbar()}</nav>
          </div>
        )}
    </div>
  );
};

SessionDetailCard.propTypes = {
  subscribers: PropTypes.array,
  createMode: PropTypes.bool,
  editMode: PropTypes.bool,
  onToggleEditMode: PropTypes.func,
  onSaveSessionSubscribers: PropTypes.func,
  onClickAttendance: PropTypes.func,
  onAddSubscriber: PropTypes.func,
  onChangeSubscriberStatus: PropTypes.func,
  users: PropTypes.array,
  session: PropTypes.object,
  onContactSubscriber: PropTypes.func,
  user: PropTypes.object,
  onOpenConfirmModal: PropTypes.func
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(SessionDetailCard);
