import * as types from './actionTypes';
import { resetUserSuccess } from './user';
import AuthService from '../../services/auth.service';

export const loggingSuccess = log => ({
  type: types.LOGGING_SUCCESS,
  log
});

export const logoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS
});

export const logout = () => {
  return dispatch => {
        dispatch(logoutSuccess());
        dispatch(resetUserSuccess());
        AuthService.logout();
      };
  };
