import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers/index';
import thunk from 'redux-thunk';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export default function configureStore(preloadedState) {
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
      : compose;

  const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: ['trainings']
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const composedEnhancers = composeEnhancers(applyMiddleware(thunk))

  const store = createStore(persistedReducer, preloadedState, composedEnhancers);

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers/index', () => store.replaceReducer(persistedReducer));
  }

  return store;
}
