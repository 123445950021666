import axios from 'axios';

class Interceptors {
  constructor() {
    this.interceptors = {};
  }

  /**
   * Define a new Axios Interceptor Request.
   * @param {string} id Interceptor Index value.
   * @param {Function} success Success callback function.
   * @param {Function} error Error callback function.
   */
  addRequestInterceptor = (id, success, error = null) => {
    const interceptor = axios.interceptors.request.use(success, error);
    Object.assign(this.interceptors, {[id]: interceptor});
  }

  /**
   * Define a new Axios Interceptor Response.
   * @param {string} id Interceptor Index value.
   * @param {Function} success Success callback function.
   * @param {Function} error Error callback function.
   */
  addResponseInterceptor = (id, success, error = null) => {
    const interceptor = axios.interceptors.response.use(success, error);
    Object.assign(this.interceptors, {[id]: interceptor});
  }

  /**
   * Remove an Axios Interceptor.
   * @param {string} id Interceptor Index value.
   */
  removeInterceptor = (id) => {
    axios.interceptors.request.eject(this.interceptors[id]);
    delete this.interceptors[id];
  }
}

export default new Interceptors();
