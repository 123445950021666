export const sessionStatusEnum = {
  DRAFT: 'Brouillon',
  OPEN: 'Ouverte',
  RATING: 'Evaluation',
  CLOSE: 'Terminée',
  CANCEL: 'Annulée'
};

const sessionStatusIndex = {
  [sessionStatusEnum.DRAFT]: 1,
  [sessionStatusEnum.OPEN]: 2,
  [sessionStatusEnum.RATING]: 3,
  [sessionStatusEnum.CLOSE]: 4,
  [sessionStatusEnum.CANCEL]: 5
};

const colorStatusEnum = {
  [sessionStatusEnum.OPEN]: 'status-color-open',
  [sessionStatusEnum.CLOSE]: 'status-color-close',
  [sessionStatusEnum.CANCEL]: 'status-color-cancel',
  [sessionStatusEnum.DRAFT]: 'status-color-draft',
  [sessionStatusEnum.RATING]: 'status-color-rating'
};

/**
 * Provide all methods relating to the use of the status enumeration.
 */
class sessionStatus {
  /**
   * Return HTML class color of session status.
   */
  static getColor = status => {
    return colorStatusEnum[status];
  };

  /**
   * Parse status enumaration in an array object of [key, value].
   */
  static getEnums = () => {
    return Object.entries(sessionStatusEnum);
  };

  /**
   * Parse status enumeration in order to retrieve the appropriate index
   */
  static getIndex = () => sessionStatusIndex;
}

export default sessionStatus;
