import { isNullOrUndefined, isEmpty } from '../utils/checking';

export const roleEnum = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  FORMER: 'ROLE_FORMER',
  ORGANISER: 'ROLE_ORGANISER'
};

/**
 * Provide all methods relating to the use of the roles enumeration.
 */
class roles {
  /**
   * Parse role enumaration in an array object of [key, value].
   */
  static getEnums = () => {
    return Object.entries(roleEnum);
  };

  /**
   * Get role ability of the user.
   * @param {Object} user user object
   * @param {(string | string[])} roles user role(s) to match
   */
  static hasAbility = (user, roles) => {
    if (isNullOrUndefined(user) || isEmpty(user)) return false;

    return user.principal.authorities.some(authority =>
        Array.isArray(roles) ? roles.includes(authority.authority) : authority.authority === roles);
  };
}

export default roles;
