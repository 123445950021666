import React from 'react';
import PropTypes from 'prop-types';

import { isNullOrUndefined } from '../../../utils/checking.js';

import '../../../styles/components/shared/Buttons/FilterButton.css';

export const FilterButton = props => {
  return (
    <button
      onClick={e => {
        e.preventDefault();
        if (!isNullOrUndefined(props.onClick)) {
          props.onClick(props.category);
        }
      }}
      className={`${props.category.isSelected ? 'btn. filter-bouton filter-bouton-selected' : 'btn. filter-bouton'} ${!isNullOrUndefined(props.onClick) ? 'clickable' : 'unclickable'}`}>
      {props.category.name}
    </button>
  );
};

FilterButton.propTypes = {
  category: PropTypes.object,
  onClick: PropTypes.func
};
