import * as types from './actionTypes';
import ApiCall from '../../api/api';
import { endpoints } from '../../api/endpoints';

export const getCategoriesSuccess = categories => ({
  type: types.GET_CATEGORIES_SUCCESS,
  categories
});

export const selectedCategorySuccess = category => ({
  type: types.UPDATE_CATEGORY_SUCCESS,
  category
});

export function getCategories() {
  return dispatch => {
    return ApiCall.get(endpoints().GETCATEGORIES)
      .then(response => {
        dispatch(
          getCategoriesSuccess(
            response.data.map(category => ({
              id: category.id,
              name: category.libelle,
              isSelected: false
            }))
          )
        );
      })
      .catch(error => {
        throw error;
      });
  };
}
