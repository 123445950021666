export const Icons = {
  ADD: {
    viewBox: '0 0 490.2 490.2',
    path: [
      'M418.5,418.5c95.6-95.6,95.6-251.2,0-346.8s-251.2-95.6-346.8,0s-95.6,251.2,0,346.8S322.9,514.1,418.5,418.5z M89,89    c86.1-86.1,226.1-86.1,312.2,0s86.1,226.1,0,312.2s-226.1,86.1-312.2,0S3,175.1,89,89z',
      'M245.1,336.9c3.4,0,6.4-1.4,8.7-3.6c2.2-2.2,3.6-5.3,3.6-8.7v-67.3h67.3c3.4,0,6.4-1.4,8.7-3.6c2.2-2.2,3.6-5.3,3.6-8.7    c0-6.8-5.5-12.3-12.2-12.2h-67.3v-67.3c0-6.8-5.5-12.3-12.2-12.2c-6.8,0-12.3,5.5-12.2,12.2v67.3h-67.3c-6.8,0-12.3,5.5-12.2,12.2    c0,6.8,5.5,12.3,12.2,12.2h67.3v67.3C232.8,331.4,238.3,336.9,245.1,336.9z'
    ]
  },
  CALENDAR: {
    viewBox: '0 0 361.77 361.77',
    path: [
      'M323.885,43.77h-27.5V25c0-13.807-11.193-25-25-25h-1c-13.807,0-25,11.193-25,25v18.77h-129V25c0-13.807-11.193-25-25-25     h-1c-13.807,0-25,11.193-25,25v18.77h-27.5c-13.807,0-25,11.193-25,25v268c0,13.809,11.193,25,25,25h286     c13.807,0,25-11.191,25-25v-268C348.885,54.963,337.691,43.77,323.885,43.77z M306.885,322.27h-252v-203h252V322.27z',
      'M89.136,211.134h43.498c2.209,0,4-1.791,4-4v-43.498c0-2.209-1.791-4-4-4H89.136c-2.209,0-4,1.791-4,4v43.498     C85.136,209.343,86.927,211.134,89.136,211.134z',
      'M159.136,211.134h43.498c2.209,0,4-1.791,4-4v-43.498c0-2.209-1.791-4-4-4h-43.498c-2.209,0-4,1.791-4,4v43.498     C155.136,209.343,156.927,211.134,159.136,211.134z',
      'M229.136,211.134h43.498c2.209,0,4-1.791,4-4v-43.498c0-2.209-1.791-4-4-4h-43.498c-2.209,0-4,1.791-4,4v43.498     C225.136,209.343,226.927,211.134,229.136,211.134z',
      'M89.136,281.134h43.498c2.209,0,4-1.791,4-4v-43.498c0-2.209-1.791-4-4-4H89.136c-2.209,0-4,1.791-4,4v43.498     C85.136,279.343,86.927,281.134,89.136,281.134z',
      'M159.136,281.134h43.498c2.209,0,4-1.791,4-4v-43.498c0-2.209-1.791-4-4-4h-43.498c-2.209,0-4,1.791-4,4v43.498     C155.136,279.343,156.927,281.134,159.136,281.134z',
      'M229.136,281.134h43.498c2.209,0,4-1.791,4-4v-43.498c0-2.209-1.791-4-4-4h-43.498c-2.209,0-4,1.791-4,4v43.498     C225.136,279.343,226.927,281.134,229.136,281.134z'
    ]
  },
  CANCEL: {
    viewBox: '0 0 305.002 305.002',
    path: [
      'M152.502,0.001C68.412,0.001,0,68.412,0,152.501s68.412,152.5,152.502,152.5c84.089,0,152.5-68.411,152.5-152.5    S236.591,0.001,152.502,0.001z M152.502,280.001C82.197,280.001,25,222.806,25,152.501c0-70.304,57.197-127.5,127.502-127.5    c70.304,0,127.5,57.196,127.5,127.5C280.002,222.806,222.806,280.001,152.502,280.001z',
      'M170.18,152.5l43.13-43.129c4.882-4.882,4.882-12.796,0-17.678c-4.881-4.882-12.796-4.881-17.678,0l-43.13,43.13    l-43.131-43.131c-4.882-4.881-12.796-4.881-17.678,0c-4.881,4.882-4.881,12.796,0,17.678l43.13,43.13l-43.131,43.131    c-4.881,4.882-4.881,12.796,0,17.679c2.441,2.44,5.64,3.66,8.839,3.66c3.199,0,6.398-1.221,8.839-3.66l43.131-43.132    l43.131,43.132c2.441,2.439,5.64,3.66,8.839,3.66s6.398-1.221,8.839-3.66c4.882-4.883,4.882-12.797,0-17.679L170.18,152.5z'
    ]
  },
  CHECKLIST: {
    viewBox: '0 0 512 512',
    path: [
      'M251.328,196.704c-6.24-6.24-16.384-6.272-22.656-0.032L176,249.376l-20.672-20.704c-6.24-6.24-16.384-6.24-22.624,0    s-6.24,16.384,0,22.624l32,32c3.104,3.136,7.2,4.704,11.296,4.704s8.192-1.568,11.328-4.672l64-64    C257.568,213.088,257.568,202.944,251.328,196.704z',
      'M251.328,324.704c-6.24-6.24-16.384-6.272-22.656-0.032L176,377.376l-20.672-20.672c-6.24-6.24-16.384-6.24-22.624,0    s-6.24,16.384,0,22.624l32,32c3.104,3.104,7.2,4.672,11.296,4.672s8.192-1.568,11.328-4.672l64-64    C257.568,341.088,257.568,330.944,251.328,324.704z',
      'M368,224h-64c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h64c8.832,0,16-7.168,16-16C384,231.168,376.832,224,368,224    z',
      'M368,352h-64c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h64c8.832,0,16-7.168,16-16C384,359.168,376.832,352,368,352    z',
      'M416,64h-64V48c0-8.832-7.168-16-16-16h-34.72C294.656,13.376,276.864,0,256,0s-38.656,13.376-45.28,32H176    c-8.832,0-16,7.168-16,16v16H96c-17.632,0-32,14.368-32,32v384c0,17.632,14.368,32,32,32h320c17.632,0,32-14.368,32-32V96    C448,78.368,433.632,64,416,64z M192,64h32c8.832,0,16-7.168,16-16c0-8.832,7.168-16,16-16c8.832,0,16,7.168,16,16    c0,8.832,7.168,16,16,16h32v32H192V64z M416,480H96V96h64v16c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16V96h64V480z'
    ]
  },
  CHECKMARK: {
    viewBox: '0 0 611.99 611.99',
    path:
      'M589.105,80.63c-30.513-31.125-79.965-31.125-110.478,0L202.422,362.344l-69.061-70.438     c-30.513-31.125-79.965-31.125-110.478,0c-30.513,31.125-30.513,81.572,0,112.678l124.29,126.776     c30.513,31.125,79.965,31.125,110.478,0l331.453-338.033C619.619,162.202,619.619,111.755,589.105,80.63z'
  },
  CLOSE: {
    viewBox: '0 0 305.002 305.002',
    path: [
      'M152.502,0.001C68.412,0.001,0,68.412,0,152.501s68.412,152.5,152.502,152.5c84.089,0,152.5-68.411,152.5-152.5    S236.591,0.001,152.502,0.001z M152.502,280.001C82.197,280.001,25,222.806,25,152.501c0-70.304,57.197-127.5,127.502-127.5    c70.304,0,127.5,57.196,127.5,127.5C280.002,222.806,222.806,280.001,152.502,280.001z',
      'M170.18,152.5l43.13-43.129c4.882-4.882,4.882-12.796,0-17.678c-4.881-4.882-12.796-4.881-17.678,0l-43.13,43.13    l-43.131-43.131c-4.882-4.881-12.796-4.881-17.678,0c-4.881,4.882-4.881,12.796,0,17.678l43.13,43.13l-43.131,43.131    c-4.881,4.882-4.881,12.796,0,17.679c2.441,2.44,5.64,3.66,8.839,3.66c3.199,0,6.398-1.221,8.839-3.66l43.131-43.132    l43.131,43.132c2.441,2.439,5.64,3.66,8.839,3.66s6.398-1.221,8.839-3.66c4.882-4.883,4.882-12.797,0-17.679L170.18,152.5z'
    ]
  },
  COMMENT: {
    viewBox: '0 0 60 60',
    path:
      'M6,2h48c3.252,0,6,2.748,6,6v33c0,3.252-2.748,6-6,6H25.442L15.74,57.673C15.546,57.885,15.276,58,15,58  c-0.121,0-0.243-0.022-0.361-0.067C14.254,57.784,14,57.413,14,57V47H6c-3.252,0-6-2.748-6-6L0,8C0,4.748,2.748,2,6,2z'
  },
  DELETE: {
    viewBox: '0 0 18.629 18.629',
    path: [
      'm16.088,0h-13.549c-0.466,0-0.845,0.379-0.845,0.846v2.541c0,0.469 0.379,0.848 0.846,0.848l.848,13.548c0,0.469 0.379,0.846 0.846,0.846h10.161c0.469,0 0.848-0.377 0.848-0.846l.846-13.548c0.469,0 0.848-0.379 0.848-0.848v-2.541c-0.002-0.467-0.381-0.846-0.849-0.846zm-2.54,16.935h-8.467l-.848-12.7h10.161l-.846,12.7zm1.694-14.395h-11.855v-0.846h11.854v0.846zm-7.621,13.55c0.469,0 0.848-0.379 0.848-0.848v-9.314c0-0.469-0.379-0.848-0.848-0.848-0.467,0-0.846,0.379-0.846,0.848v9.314c0,0.469 0.379,0.848 0.846,0.848zm3.387,0c0.469,0 0.848-0.379 0.848-0.848v-9.314c0-0.469-0.379-0.848-0.848-0.848-0.467,0-0.846,0.379-0.846,0.848v9.314c0,0.469 0.379,0.848 0.846,0.848z'
    ]
  },
  DETAIL: {
    viewBox: '0 0 60 60',
    path: [
      'M8,22c-4.411,0-8,3.589-8,8s3.589,8,8,8s8-3.589,8-8S12.411,22,8,22z',
      'M52,22c-4.411,0-8,3.589-8,8s3.589,8,8,8s8-3.589,8-8S56.411,22,52,22z',
      'M30,22c-4.411,0-8,3.589-8,8s3.589,8,8,8s8-3.589,8-8S34.411,22,30,22z'
    ]
  },
  DOWNLOAD: {
    viewBox: '0 0 512 512',
    path: [
      'M382.56,233.376C379.968,227.648,374.272,224,368,224h-64V16c0-8.832-7.168-16-16-16h-64c-8.832,0-16,7.168-16,16v208h-64    c-6.272,0-11.968,3.68-14.56,9.376c-2.624,5.728-1.6,12.416,2.528,17.152l112,128c3.04,3.488,7.424,5.472,12.032,5.472    c4.608,0,8.992-2.016,12.032-5.472l112-128C384.192,245.824,385.152,239.104,382.56,233.376z',
      'M432,352v96H80v-96H16v128c0,17.696,14.336,32,32,32h416c17.696,0,32-14.304,32-32V352H432z'
    ]
  },
  EDIT: {
    viewBox: '0 0 512 512',
    path:
      'M 497.941 14.057 c 18.75 18.75 18.719 49.141 0 67.891 l -22.625 22.625 L 407.41 36.682 l 22.625 -22.625 C 448.784 -4.677 479.191 -4.693 497.941 14.057 Z M 158.534 285.588 l -22.609 90.5 l 90.5 -22.625 l 226.266 -226.266 l -67.906 -67.891 L 158.534 285.588 Z M 384.003 241.15 v 206.844 h -320 v -320 h 206.859 l 63.983 -64 H 0.003 v 448 h 448 v -334.86 L 384.003 241.15 Z'
  },
  FORWARDEVAL: {
    viewBox: '0 0 512 512',
    path: [
      'M477.606,128.055C443.431,68.862,388.251,26.52,322.229,8.83C256.207-8.862,187.248,0.217,128.055,34.394    C68.863,68.57,26.52,123.75,8.83,189.772c-17.69,66.021-8.611,134.979,25.564,194.173    c34.175,59.194,89.355,101.535,155.377,119.225c22.046,5.908,44.417,8.83,66.646,8.83c44.339-0.001,88.101-11.629,127.529-34.393    C443.138,443.432,485.48,388.25,503.17,322.23C520.862,256.207,511.781,187.249,477.606,128.055z M477.431,315.333    c-15.849,59.146-53.78,108.579-106.81,139.197s-114.808,38.748-173.954,22.903c-59.146-15.849-108.579-53.78-139.197-106.81    c-30.616-53.028-38.75-114.807-22.901-173.954c15.849-59.146,53.78-108.579,106.81-139.197s114.809-38.749,173.954-22.901    c59.146,15.849,108.579,53.78,139.197,106.81C485.145,194.408,493.28,256.186,477.431,315.333z',
      'M425.587,235.058l-122.681-70.829c-7.571-4.372-16.612-4.372-24.182,0c-7.571,4.37-12.09,12.2-12.09,20.941v52.802    c-1.227-1.087-2.576-2.067-4.041-2.913L139.911,164.23c-7.571-4.372-16.612-4.372-24.182,0c-7.571,4.37-12.09,12.2-12.09,20.941    v141.661c0,8.742,4.521,16.571,12.09,20.941c3.787,2.186,7.938,3.279,12.092,3.279c4.152,0,8.305-1.093,12.09-3.279    l122.681-70.829c1.467-0.846,2.815-1.825,4.041-2.913v52.802c0,8.742,4.521,16.571,12.09,20.941    c3.787,2.186,7.938,3.279,12.092,3.279c4.152,0,8.305-1.093,12.09-3.279l122.681-70.829c7.572-4.37,12.09-12.2,12.09-20.943    C437.677,247.26,433.159,239.428,425.587,235.058z M130.284,322.56V189.442l115.284,66.559L130.284,322.56z M293.28,322.56    V189.442l115.284,66.559L293.28,322.56z'
    ]
  },
  HISTORY: {
    viewBox: '0 0 512 512',
    path: [
      'M449,0H149c-34.738,0-63,28.262-63,63v323H20c-11.046,0-20,8.954-20,20v43c0,34.738,28.262,63,63,63h300    c34.738,0,63-28.262,63-63V126h66c11.046,0,20-8.954,20-20V63C512,28.262,483.738,0,449,0z M63,472c-12.682,0-23-10.317-23-23v-23    c6.283,0,255.769,0,260,0v23c0,8.089,1.624,16.086,4.347,23H63z M386,63c0,33.018,0,367.344,0,386c0,12.683-10.317,23-23,23    c-12.683,0-23-10.317-23-23v-43c0-11.046-8.954-20-20-20H126V63c0-12.683,10.318-23,23-23h241.347C387.62,46.92,386,54.928,386,63    z M472,86h-46V63c0-12.683,10.317-23,23-23c12.683,0,23,10.317,23,23V86z'
    ]
  },
  LIST: {
    viewBox: '-64 0 511 511.9999',
    path: [
      'm336.472656 64.035156h-47.996094v-16c0-8.835937-7.160156-16-15.996093-16h-34.734375c-8.820313-24.996094-36.238282-38.109375-61.234375-29.285156-13.6875 4.832031-24.457031 15.597656-29.289063 29.285156h-34.730468c-8.835938 0-16 7.164063-16 16v16h-47.996094c-26.507813 0-47.996094 21.488282-47.996094 47.996094v351.972656c0 26.507813 21.488281 47.996094 47.996094 47.996094h287.980468c26.507813 0 47.996094-21.488281 47.996094-47.996094v-351.972656c-.003906-26.507812-21.492187-47.996094-48-47.996094zm-207.984375 0h32c8.835938 0 16-7.164062 16-16 0-8.835937 7.160157-16 15.996094-16s16 7.164063 16 16c0 8.835938 7.164063 16 16 16h31.996094v31.996094h-127.992188zm223.984375 399.96875c0 8.835938-7.164062 16-16 16h-287.976562c-8.835938 0-16-7.164062-16-16v-351.972656c0-8.835938 7.164062-16 16-16h47.996094v16c0 8.835938 7.164062 15.996094 16 15.996094h159.988281c8.835937 0 15.996093-7.160156 15.996093-15.996094v-16h48c8.835938 0 15.996094 7.164062 15.996094 16zm0 0',
      'm288.476562 192.023438h-191.984374c-8.835938 0-16 7.164062-16 16 0 8.835937 7.164062 16 16 16h191.984374c8.835938 0 16-7.164063 16-16 0-8.835938-7.164062-16-16-16zm0 0',
      'm288.476562 288.015625h-191.984374c-8.835938 0-16 7.164063-16 16s7.164062 16 16 16h191.984374c8.835938 0 16-7.164063 16-16s-7.164062-16-16-16zm0 0',
      'm288.476562 384.011719h-191.984374c-8.835938 0-16 7.160156-16 15.996093 0 8.835938 7.164062 16 16 16h191.984374c8.835938 0 16-7.164062 16-16 0-8.835937-7.164062-15.996093-16-15.996093zm0 0'
    ]
  },
  MAIL: {
    viewBox: '0 0 512 512',
    path: [
      'M365.922,198.724L256,262.846l-109.922-64.121c-9.541-5.564-21.787-2.344-27.353,7.198     c-5.566,9.541-2.343,21.787,7.198,27.353l120,70c6.226,3.632,13.927,3.633,20.154-0.001l120-70     c9.541-5.565,12.764-17.812,7.198-27.353C387.71,196.38,375.461,193.158,365.922,198.724z',
      'M452,76H60c-33.084,0-60,26.916-60,60v240c0,33.084,26.916,60,60,60h392c33.084,0,60-26.916,60-60V136     C512,102.916,485.084,76,452,76z M472,376c0,11.028-8.972,20-20,20H60c-11.028,0-20-8.972-20-20V136c0-11.028,8.972-20,20-20h392     c11.028,0,20,8.972,20,20V376z'
    ]
  },
  NOTIFYOPENING: {
    viewBox: '0 0 512.028 512.028',
    path: [
      'M374.931,368.198c-2.048-5.525-8.192-8.363-13.696-6.357L28.585,483.462l121.237-331.456    c2.027-5.547-0.832-11.669-6.357-13.696c-5.547-2.027-11.669,0.832-13.696,6.357L0.659,497.713    c-1.429,3.883-0.469,8.256,2.475,11.2c2.048,2.027,4.779,3.115,7.552,3.115c1.237,0,2.475-0.213,3.669-0.64l354.219-129.493    C374.099,379.868,376.958,373.745,374.931,368.198z',
      'M293.054,218.993c-58.133-58.155-132.331-109.163-157.717-83.648c-25.472,25.472,25.493,99.605,83.627,157.739    c47.595,47.573,105.899,90.368,139.456,90.368c7.445,0,13.653-2.112,18.261-6.72C402.153,351.26,351.187,277.126,293.054,218.993z     M361.598,361.628c-6.421,6.443-58.944-15.04-127.552-83.648c-68.587-68.587-90.005-121.173-83.627-127.552    c0.704-0.704,1.984-1.067,3.776-1.067c14.4,0,62.763,23.68,123.776,84.715C346.558,302.662,367.977,355.249,361.598,361.628z',
      'M177.299,43.121c-5.803-0.853-11.179,3.413-11.904,9.259l-5.291,42.325c-0.725,5.845,3.413,11.179,9.259,11.904     c0.448,0.064,0.896,0.085,1.323,0.085c5.312,0,9.899-3.947,10.581-9.323l5.291-42.325     C187.283,49.18,183.145,43.846,177.299,43.121z',
      'M182.675,0.113c-5.867-0.875-11.179,3.435-11.904,9.259l-0.085,0.661c-0.725,5.845,3.456,10.859,9.301,11.584     c0.427,0.064,0.853,0.085,1.259,0.085c5.333,0,10.005-4.267,10.688-9.664C192.659,6.172,188.521,0.838,182.675,0.113z',
      'M457.022,325.468l-42.325,5.291c-5.845,0.747-9.984,6.08-9.259,11.925c0.683,5.397,5.269,9.344,10.581,9.344     c0.427,0,0.875-0.021,1.323-0.085l42.325-5.291c5.845-0.747,9.984-6.08,9.259-11.925     C468.179,328.881,462.974,324.593,457.022,325.468z',
      'M511.934,329.329c-0.747-5.845-6.08-9.984-11.925-9.237l-0.661,0.085c-5.824,0.768-9.643,6.059-8.896,11.883     c0.683,5.397,5.547,9.301,10.88,9.301c0.448,0,0.917-0.021,1.365-0.085C508.542,340.508,512.681,335.174,511.934,329.329z',
      'M292.179,0.86c-5.397-2.24-11.691,0.256-13.973,5.696l-8.299,19.648c-2.304,5.419,0.256,11.691,5.675,13.973    c1.344,0.555,2.752,0.832,4.139,0.832c4.16,0,8.107-2.432,9.835-6.528l8.299-19.648C300.158,9.414,297.598,3.142,292.179,0.86z',
      'M259.753,77.66c-5.461-2.24-11.691,0.235-13.973,5.696l-8.299,19.648c-2.283,5.419,0.256,11.691,5.675,13.973    c1.365,0.555,2.773,0.832,4.16,0.832c4.16,0,8.107-2.453,9.813-6.528l8.299-19.648C267.71,86.214,265.171,79.942,259.753,77.66z',
      'M329.619,124.614c-4.565-3.733-11.285-3.072-15.019,1.493l-13.504,16.512c-3.733,4.565-3.051,11.264,1.515,14.997     c1.984,1.621,4.373,2.411,6.741,2.411c3.093,0,6.144-1.323,8.277-3.904l13.504-16.512     C334.867,135.046,334.185,128.348,329.619,124.614z',
      'M410.665,25.542c-4.565-3.733-11.285-3.072-15.019,1.493l-13.504,16.512c-3.733,4.565-3.051,11.264,1.515,14.997     c1.984,1.621,4.373,2.411,6.741,2.411c3.093,0,6.144-1.323,8.277-3.904l13.504-16.512     C415.913,35.974,415.23,29.276,410.665,25.542z',
      'M370.131,75.1c-4.565-3.755-11.285-3.072-15.019,1.493l-13.504,16.491c-3.733,4.565-3.051,11.264,1.515,14.997     c1.984,1.621,4.373,2.411,6.741,2.411c3.093,0,6.165-1.344,8.277-3.904l13.504-16.491     C375.379,85.532,374.697,78.833,370.131,75.1z',
      'M374.206,179.505l-16.512,13.504c-4.565,3.733-5.248,10.432-1.515,14.997c2.112,2.581,5.184,3.925,8.277,3.925     c2.368,0,4.757-0.789,6.741-2.432l16.512-13.504c4.565-3.733,5.248-10.432,1.515-14.997     C385.47,176.433,378.75,175.729,374.206,179.505z',
      'M423.742,138.95l-16.512,13.525c-4.565,3.733-5.227,10.453-1.493,14.997c2.091,2.581,5.163,3.925,8.256,3.925     c2.368,0,4.757-0.789,6.741-2.411l16.533-13.547c4.565-3.733,5.227-10.453,1.493-14.997     C435.027,135.857,428.329,135.196,423.742,138.95z',
      'M488.297,99.932c-3.776-4.587-10.475-5.248-15.019-1.493l-16.512,13.504c-4.565,3.733-5.248,10.432-1.515,14.997     c2.112,2.581,5.184,3.925,8.277,3.925c2.368,0,4.757-0.789,6.72-2.411l16.533-13.525     C491.347,111.196,492.03,104.497,488.297,99.932z',
      'M511.529,220.87c-1.728-5.632-7.68-8.789-13.333-7.04l-20.395,6.293c-5.632,1.728-8.789,7.701-7.04,13.333    c1.408,4.587,5.632,7.531,10.197,7.531c1.045,0,2.112-0.149,3.136-0.491l20.395-6.293    C510.121,232.476,513.278,226.502,511.529,220.87z',
      'M435.539,244.358c-1.728-5.632-7.68-8.789-13.333-7.04l-20.395,6.293c-5.632,1.728-8.789,7.701-7.04,13.333    c1.408,4.587,5.632,7.531,10.197,7.531c1.045,0,2.091-0.149,3.136-0.491l20.395-6.293    C434.131,255.964,437.289,249.99,435.539,244.358z'
    ]
  },
  POWER: {
    viewBox: '0 0 475.085 475.085',
    path: [
      'M237.545,255.816c9.899,0,18.468-3.609,25.696-10.848c7.23-7.229,10.854-15.799,10.854-25.694V36.547    c0-9.9-3.62-18.464-10.854-25.693C256.014,3.617,247.444,0,237.545,0c-9.9,0-18.464,3.621-25.697,10.854    c-7.233,7.229-10.85,15.797-10.85,25.693v182.728c0,9.895,3.617,18.464,10.85,25.694    C219.081,252.207,227.648,255.816,237.545,255.816z',
      'M433.836,157.887c-15.325-30.642-36.878-56.339-64.666-77.084c-7.994-6.09-17.035-8.47-27.123-7.139    c-10.089,1.333-18.083,6.091-23.983,14.273c-6.091,7.993-8.418,16.986-6.994,26.979c1.423,9.998,6.139,18.037,14.133,24.128    c18.645,14.084,33.072,31.312,43.25,51.678c10.184,20.364,15.27,42.065,15.27,65.091c0,19.801-3.854,38.688-11.561,56.678    c-7.706,17.987-18.13,33.544-31.265,46.679c-13.135,13.131-28.688,23.551-46.678,31.261c-17.987,7.71-36.878,11.57-56.673,11.57    c-19.792,0-38.684-3.86-56.671-11.57c-17.989-7.71-33.547-18.13-46.682-31.261c-13.129-13.135-23.551-28.691-31.261-46.679    c-7.708-17.99-11.563-36.877-11.563-56.678c0-23.026,5.092-44.724,15.274-65.091c10.183-20.364,24.601-37.591,43.253-51.678    c7.994-6.095,12.703-14.133,14.133-24.128c1.427-9.989-0.903-18.986-6.995-26.979c-5.901-8.182-13.844-12.941-23.839-14.273    c-9.994-1.332-19.085,1.049-27.268,7.139c-27.792,20.745-49.344,46.442-64.669,77.084c-15.324,30.646-22.983,63.288-22.983,97.927    c0,29.697,5.806,58.054,17.415,85.082c11.613,27.028,27.218,50.34,46.826,69.948c19.602,19.603,42.919,35.215,69.949,46.815    c27.028,11.615,55.388,17.426,85.08,17.426c29.693,0,58.052-5.811,85.081-17.426c27.031-11.604,50.347-27.213,69.952-46.815    c19.602-19.602,35.207-42.92,46.818-69.948s17.412-55.392,17.412-85.082C456.809,221.174,449.16,188.532,433.836,157.887z'
    ]
  },
  PROGRAM: {
    viewBox: '0 0 985 985',
    path: [
      'M915,61.55H70c-38.7,0-70,31.3-70,70c0,38.7,31.3,70,70,70h845c38.7,0,70-31.3,70-70C985,92.85,953.7,61.55,915,61.55z',
      'M985,612.851c0-38.701-31.3-70-70-70H70c-38.7,0-70,31.299-70,70c0,38.699,31.3,70,70,70h845   C953.7,682.851,985,651.45,985,612.851z',
      'M70,442.15h695.4c38.699,0,70-31.3,70-70s-31.301-70-70-70H70c-38.7,0-70,31.3-70,70S31.3,442.15,70,442.15z',
      'M592.3,853.45c0-38.701-31.3-70-70-70H70c-38.7,0-70,31.299-70,70c0,38.699,31.3,70,70,70h452.3   C561,923.45,592.3,892.149,592.3,853.45z'
    ]
  },
  REMOVE: {
    viewBox: '0 0 129 129',
    path: [
      'm64.5,122.4c31.9,0 57.9-26 57.9-57.9s-26-57.9-57.9-57.9-57.9,26-57.9,57.9 26,57.9 57.9,57.9zm0-107.7c27.4-3.55271e-15 49.8,22.3 49.8,49.8s-22.3,49.8-49.8,49.8-49.8-22.4-49.8-49.8 22.4-49.8 49.8-49.8z',
      'M37.8,68h53.3c2.3,0,4.1-1.8,4.1-4.1s-1.8-4.1-4.1-4.1H37.8c-2.3,0-4.1,1.8-4.1,4.1S35.6,68,37.8,68z'
    ]
  },
  SADFACE: {
    viewBox: '0 0 490 490',
    path: [
      'M140.1,364.15c4.396-3.26,108.773-78.91,214.584,0l18.303-24.554c-124.399-92.81-249.965-0.912-251.221,0.015L140.1,364.15   z',
      'M69.086,490h351.829C459.001,490,490,459.001,490,420.914V69.086C490,30.991,459.001,0,420.914,0H69.086   C30.999,0,0,30.991,0,69.086v351.829C0,459.001,30.999,490,69.086,490z M30.625,69.086c0-21.204,17.256-38.461,38.461-38.461   h351.829c21.204,0,38.461,17.257,38.461,38.461v351.829c0,21.204-17.257,38.461-38.461,38.461H69.086   c-21.204,0-38.461-17.257-38.461-38.461V69.086z'
    ],
    polygon: [
      '138.171,228.76 166.449,200.48 194.726,228.76 216.379,207.107 188.101,178.827 216.379,150.546 194.726,128.893    166.449,157.173 138.171,128.893 116.519,150.546 144.797,178.827 116.519,207.107  ',
      '295.274,228.76 323.551,200.48 351.829,228.76 373.481,207.107 345.203,178.827 373.481,150.546 351.829,128.893    323.551,157.173 295.274,128.893 273.621,150.546 301.899,178.827 273.621,207.107  '
    ]
  },
  SAVE: {
    viewBox: '0 0 512 512',
    path: [
      'M438.284,0H60C26.916,0,0,26.916,0,60v392c0,33.084,26.916,60,60,60h392c33.084,0,60-26.916,60-60V73.716L438.284,0z     M120,40h230v118H120V40z M392,472H120V314h272V472z M472,452c0,11.028-8.972,20-20,20h-20V274H80v198H60    c-11.028,0-20-8.972-20-20V60c0-11.028,8.972-20,20-20h20v158h310V40h31.716L472,90.284V452z'
    ],
    rect: {
      x: 274,
      y: 59,
      width: 40,
      height: 79
    }
  },
  SCHEDULEDSESSIONS: {
    viewBox: '0 0 433.633 433.633',
    path: [
      'M388.749,47.038c-0.886-0.036-1.773-0.042-2.66-0.017h-33.437V18.286C352.653,6.792,341.681,0,330.187,0h-30.824     c-11.494,0-19.853,6.792-19.853,18.286V47.02H154.122V18.286C154.122,6.792,145.763,0,134.269,0h-30.825     C91.951,0,80.979,6.792,80.979,18.286V47.02H47.543C26.199,46.425,8.414,63.246,7.819,84.589     c-0.025,0.886-0.019,1.774,0.017,2.66v301.975c0,22.988,16.718,44.408,39.706,44.408H386.09c22.988,0,39.706-21.42,39.706-44.408     V87.249C426.67,65.915,410.083,47.912,388.749,47.038z M299.363,20.898h32.392v57.469h-32.392V20.898z M103.445,20.898h29.78     v57.469h-29.78V20.898z M404.898,389.224c0,11.494-7.314,23.51-18.808,23.51H47.543c-11.494,0-18.808-12.016-18.808-23.51     V167.184h376.163V389.224z M404.898,87.249v59.037H28.734V87.249c-0.885-9.77,6.318-18.408,16.088-19.293     c0.904-0.082,1.814-0.094,2.72-0.037h33.437v11.494c0,11.494,10.971,19.853,22.465,19.853h30.825     c10.672,0.293,19.56-8.122,19.853-18.794c0.01-0.353,0.01-0.706,0-1.059V67.918H279.51v11.494     c-0.293,10.672,8.122,19.56,18.794,19.853c0.353,0.01,0.706,0.01,1.059,0h30.825c11.494,0,22.465-8.359,22.465-19.853V67.918     h33.437c9.791-0.617,18.228,6.82,18.845,16.611C404.992,85.435,404.98,86.345,404.898,87.249z'
    ]
  },
  TIME: {
    viewBox: '0 0 512 512',
    path: [
      'M437.019,74.981C388.667,26.629,324.38,0,256,0S123.333,26.63,74.981,74.981S0,187.62,0,256    s26.629,132.667,74.981,181.019C123.332,485.371,187.62,512,256,512s132.667-26.629,181.019-74.981    C485.371,388.667,512,324.38,512,256S485.371,123.332,437.019,74.981z M256,470.636C137.65,470.636,41.364,374.35,41.364,256    S137.65,41.364,256,41.364S470.636,137.65,470.636,256S374.35,470.636,256,470.636z',
      'M341.221,311.97l-64.538-64.537V114.809c0-11.422-9.259-20.682-20.682-20.682c-11.422,0-20.682,9.26-20.682,20.682V256    c0,5.486,2.179,10.746,6.058,14.625l70.594,70.595c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.586-2.019,14.626-6.058    C349.297,333.142,349.297,320.047,341.221,311.97z'
    ]
  },
  UPLOAD: {
    viewBox: '0 0 512 512',
    path: [
      'M380.032,133.472l-112-128C264.992,2.016,260.608,0,256,0c-4.608,0-8.992,2.016-12.032,5.472l-112,128    c-4.128,4.736-5.152,11.424-2.528,17.152C132.032,156.32,137.728,160,144,160h64v208c0,8.832,7.168,16,16,16h64    c8.832,0,16-7.168,16-16V160h64c6.272,0,11.968-3.648,14.56-9.376C385.152,144.896,384.192,138.176,380.032,133.472z',
      'M432,352v96H80v-96H16v128c0,17.696,14.336,32,32,32h416c17.696,0,32-14.304,32-32V352H432z'
    ]
  },
  USER: {
    viewBox: '0 0 60 60',
    path: [
      'M41.014,45.389l-9.553-4.776C30.56,40.162,30,39.256,30,38.248v-3.381c0.229-0.28,0.47-0.599,0.719-0.951   c1.239-1.75,2.232-3.698,2.954-5.799C35.084,27.47,36,26.075,36,24.5v-4c0-0.963-0.36-1.896-1-2.625v-5.319   c0.056-0.55,0.276-3.824-2.092-6.525C30.854,3.688,27.521,2.5,23,2.5s-7.854,1.188-9.908,3.53   c-2.368,2.701-2.148,5.976-2.092,6.525v5.319c-0.64,0.729-1,1.662-1,2.625v4c0,1.217,0.553,2.352,1.497,3.109   c0.916,3.627,2.833,6.36,3.503,7.237v3.309c0,0.968-0.528,1.856-1.377,2.32l-8.921,4.866C1.801,46.924,0,49.958,0,53.262V57.5h46   v-4.043C46,50.018,44.089,46.927,41.014,45.389z',
      'M55.467,46.526l-9.723-4.21c-0.23-0.115-0.485-0.396-0.704-0.771l6.525-0.005c0,0,0.377,0.037,0.962,0.037   c1.073,0,2.638-0.122,4-0.707c0.817-0.352,1.425-1.047,1.669-1.907c0.246-0.868,0.09-1.787-0.426-2.523   c-1.865-2.654-6.218-9.589-6.354-16.623c-0.003-0.121-0.397-12.083-12.21-12.18c-1.187,0.01-2.309,0.156-3.372,0.413   c0.792,2.094,0.719,3.968,0.665,4.576v4.733c0.648,0.922,1,2.017,1,3.141v4c0,1.907-1.004,3.672-2.607,4.662   c-0.748,2.022-1.738,3.911-2.949,5.621c-0.15,0.213-0.298,0.414-0.443,0.604v2.86c0,0.442,0.236,0.825,0.631,1.022l9.553,4.776   c3.587,1.794,5.815,5.399,5.815,9.41V57.5H60v-3.697C60,50.711,58.282,47.933,55.467,46.526z'
    ]
  }
};
