import * as types from './actionTypes';
import ApiCall from '../../api/api';
import { endpoints } from '../../api/endpoints';

export const getOrganizationsSuccess = organizations => ({
  type: types.GET_ORGANIZATIONS_SUCCESS,
  organizations
});

export function getOrganizations() {
  return dispatch => {
    return ApiCall.get(endpoints().GETORGANIZATIONS)
      .then(response => {
        dispatch(getOrganizationsSuccess(response.data));
      })
      .catch(error => {
        throw error;
      });
  };
}
