import React from 'react';
import { Translate } from 'react-localize-redux';

import '../../../styles/components/shared/Loading/Loading.css';

const Loading = () => (
    <div className="loading rounded p-2">
        <div className="d-flex flex-column align-items-center m-4">
            <ul className="flex-row" role="progressbar" aria-busy="true" aria-label="Loading">
                <li role="presentation" />
                <li role="presentation" />
                <li role="presentation" />
                <li role="presentation" />
                <li role="presentation" />
                <li role="presentation" />
                <li role="presentation" />
            </ul>
            <p className="flex-row"><Translate id="loading.message"/></p>
        </div>

    </div>
)

export default Loading;