/* eslint-disable jsx-a11y/href-no-hash */

import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

import { Icon } from '../../shared/Icons/Index';

export const ClickButton = props => {
  return (
    <Translate>
      {({ translate }) => (
        <a href="#empty" onClick={props.onClick} className={props.iconOnly ? '' : props.className}>
          {props.icon && (
            <Icon
              icon={props.icon}
              className={props.iconOnly ? props.className : 'icon-pictogram'}
              title={props.label && translate(props.label)}
            />
          )}
          {(props.label && !props.iconOnly) && (
            <span>{translate(props.label)}</span>
          )}
        </a>
      )}
    </Translate>
  );
};

ClickButton.defaultProps = {
  className: '',
  iconOnly: false
};

ClickButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.any,
  iconOnly: PropTypes.bool
};
