import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import { endpoints } from '../../api/endpoints';
import ApiCall from '../../api/api';

import sessionStatus, { sessionStatusEnum } from '../../enums/sessionStatus';
import subscriberStatus, {
  subscriberStatusEnum
} from '../../enums/subscriberStatus';

import SessionDetailSideBar from '../../components/layers/Sessions/SessionDetailSideBar';
import SessionDetailSideBarEdit from '../../components/layers/Sessions/SessionDetailSideBarEdit';
import SessionDetailCard from '../../components/layers/Sessions/SessionDetailCard';
import { getTrainings } from '../../redux/actions/trainings';
import { addSubscriber, updateSubscriberStatus } from '../../api/subscribers';
import getAttendanceSheet from '../../utils/attendanceSheet';
import { getClosestDateToToday } from '../../utils/parseDate';
import { loadErrors } from '../../redux/actions/error';

import { EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import Modal from 'react-responsive-modal';
import ContactSubscribers from '../../components/layers/ContactSubscribers/ContactSubscribers';

import ConfirmModal from '../../components/shared/Modal/ConfirmModal';
import AlertModal from '../../components/shared/Modal/AlertModal';

import { Translate } from 'react-localize-redux';
import { isNullOrUndefined } from '../../utils/checking';

class SessionContainer extends React.Component {
  constructor(props) {
    super(props);

    let listStatus = [];

    subscriberStatus.getEnums().forEach(roles => {
      listStatus.push({ value: roles[0], label: roles[1] });
    });

    let today = new Date();
    today.setHours(19, 0, 0);

    this.state = {
      today: today,
      //SESSION PROPS
      idParam: null,
      planificationToDelete: [],
      sessions: [
        {
          sessionFormation: {
            commentaire: '',
            dateSession: today,
            dates: [],
            financement: { id: 0, libelle: '' },
            formateur: '',
            formation: {},
            id: null,
            lieu: '',
            montantFinanceHT: 0,
            montantHT: 0,
            nbMax: 0,
            statutSession: {}
          },
          dateSession: today,
          duree: 1.5
        }
      ],
      subscribers: [],
      prevSubscribers: [],
      newSubscribers: [],
      financers: [],
      detailsEditMode: false,
      subscribersEditMode: false,
      users: [],
      defaultSessionAddress: "",
      editSessionAddress: "",
      prevSessions: [],
      getUrlParam: {
        0: 'edit',
        1: 'new'
      },
      createMode: false,

      // MODAL CONTACT SUBSCRIBERS PROPS
      isModalOpen: false,
      editorState: EditorState.createEmpty(),
      listStatus: listStatus,
      selectOptions: [listStatus[0]],

      // CONFIRM MODAL
      isConfirmModalOpen: false,
      isConfirmDeleteModalOpen: false,
      selectedId: null,

      // ALERT MODAL
      isMessageSentModalOpen: false
    };

    this.getFinancers = this.getFinancers.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.getDefaultSessionAddress = this.getDefaultSessionAddress.bind(this);

    this.enableDetailsEditMode = this.enableDetailsEditMode.bind(this);
    this.handleToggleSubscribersEditMode = this.handleToggleSubscribersEditMode.bind(this);
    this.handleSaveSessionSubscribers = this.handleSaveSessionSubscribers.bind(this);
    this.handleDeleteSession = this.handleDeleteSession.bind(this);
    this.handleOpenConfirmDeleteModal = this.handleOpenConfirmDeleteModal.bind(this);
    this.handleCloseConfirmDeleteModal = this.handleCloseConfirmDeleteModal.bind(this);
    this.handleAddSubscriber = this.handleAddSubscriber.bind(this);
    this.handleChangeSubscriberStatus = this.handleChangeSubscriberStatus.bind(this);
    this.handleCancelChanges = this.handleCancelChanges.bind(this);
    this.handleSessionUpdate = this.handleSessionUpdate.bind(this);

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDurationChange = this.handleDurationChange.bind(this);
    this.handleAddDate = this.handleAddDate.bind(this);
    this.handleDeleteDate = this.handleDeleteDate.bind(this);
    this.handleClickAttendance = this.handleClickAttendance.bind(this);
    this.handlePlaceChange = this.handlePlaceChange.bind(this);

    this.handleClickModalOpen = this.handleClickModalOpen.bind(this);
    this.handleClickModalClose = this.handleClickModalClose.bind(this);
    this.handleContentStateChange = this.handleContentStateChange.bind(this);

    this.handleSelectStatusChange = this.handleSelectStatusChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.handleOpenConfirmModal = this.handleOpenConfirmModal.bind(this);
    this.handleCloseConfirmModal = this.handleCloseConfirmModal.bind(this);
    this.handleClickContactNotification = this.handleClickContactNotification.bind(this);

    this.handleCloseMessageSentModal = this.handleCloseMessageSentModal.bind(this);
  }

  async componentDidMount() {
    const {
      match: { params }
    } = this.props;

    await this.setState({
      idParam: params.id,
      planificationToDelete: []
    });

    /**
     * Retrieve everything we need to display session informations
     */

    // On create session
    if (this.state.getUrlParam[0] !== params[0]) {
      this.enableDetailsEditMode();
      this.getSession(this.state.sessions);
    }

    // On edit session
    if (this.state.getUrlParam[0] === params[0]) {
      this.getSessionDetails();
      this.getSubscriptions();
    }

    // Get all trainings if we do not have them
    if (this.props.trainings.length === 0) this.props.getTrainings();

    const referentials = [
      this.getFinancers,
      this.getUsers,
      this.getDefaultSessionAddress,
    ];

    const promiseall = await Promise.all(referentials.map((ref) =>
       ref()
    ));

    let editSessionAddress = "";

    if (this.state.getUrlParam[0] === params[0]) {
      editSessionAddress = this.state.sessions[0].sessionFormation.lieu;
    } else if (promiseall[2].status === 200) {
      editSessionAddress = promiseall[2].data[0];
    }

    this.setState(state => ({
      financers: promiseall[0].status === 200 ? promiseall[0].data : [],
      users: promiseall[1].status === 200 ? promiseall[1].data : [],
      defaultSessionAddress: promiseall[2].status === 200 ? promiseall[2].data[0] : "",
      editSessionAddress: editSessionAddress,
      createMode: state.getUrlParam[0] !== params[0]
     }));
  }

  /**
   * Return the number of session subscibers.
   */
  getNbSubscribers() {
    let nbSubscribers = 0;
    this.state.subscribers.forEach(s => {
      if (s.inscription.statutInscription.libelle !== subscriberStatusEnum.CANCEL)
        nbSubscribers++;
    });

    return nbSubscribers;
  }

  ArrayCopy = data => {
    return data.map(obj => {
      obj = { ...obj };
      return obj;
    });
  };

  async getSessionDetails() {
    let response = await ApiCall.get(
      endpoints(this.state.idParam).GETSESSIONBYID
    );

    this.getSession(response.data);
  }

  async getSubscriptions() {
    let response = await ApiCall.get(
      endpoints(this.state.idParam).GETINSCRIPTIONSESSIONDETAIL
    );
    this.setState({
      subscribers: response.data,
      prevSubscribers: this.ArrayCopy(response.data)
    });
  }

  getFinancers() {
    try {
      return ApiCall.get(endpoints().GETFINANCEMENT);
    } catch (err) {
      console.error(err);
    }
  }

  getUsers() {
    try {
      return ApiCall.get(endpoints().GETUSERS, {sort:['lastName,asc', 'firstName,asc']});
    } catch (err) {
      console.error(err);
    }
  }

  getDefaultSessionAddress() {
    try {
      return ApiCall.get(endpoints().DEFAULTSESSIONADDRESS );
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * get a proper object of every sessions date
   */
  getPlanifications(data) {
    let dates = [];

    data.forEach(p => {
      let date = p.dateSession;

      let hour = Math.floor(p.duree);
      let minutes = Math.round((p.duree - Math.floor(p.duree)) * 60);

      let duration = new Date();
      duration.setHours(hour);
      duration.setMinutes(minutes);

      let obj = {
        id: p.id,
        date: date,
        duration: duration,
        moment: moment(date)
      };

      dates.push(obj);
    });

    return dates;
  }

  /**
   * set every session date in the first index of sessions ( every index has the same info but date - which we concated by ourselves )
   */
  async getSession(sessions) {
    const dates = this.getPlanifications(sessions);

    sessions[0].sessionFormation.dates = dates;

    await this.setState({
      sessions: this.ArrayCopy(sessions)
    });
  }

  async enableDetailsEditMode() {
    let sessions = this.state.sessions.slice();
    await this.setState({ detailsEditMode: true, prevSessions: this.ArrayCopy(sessions) });
  }

  /**
   * Toggle edit mode.
   */
  handleToggleSubscribersEditMode(e) {
    const that = this;
    e.preventDefault();

    if (this.state.subscribersEditMode) {
      // Cancel subscribers edited values. Refill with old values.
      this.setState(state => ({
        subscribers: that.ArrayCopy(state.prevSubscribers)
      }));
    }

    this.setState({ subscribersEditMode: !this.state.subscribersEditMode });
  }

  handleSaveSessionSubscribers() {
    const that = this;
    const diff = Object.keys(this.state.subscribers).reduce((diff, key) => {
      if (isNullOrUndefined(that.state.prevSubscribers[key]) ||
        that.state.prevSubscribers[key].statutInscription.id === that.state.subscribers[key].statutInscription.id)
        return diff;
      return { ...diff, [key]: that.state.subscribers[key] };
    }, {});

    // Update status if needed.
    Object.keys(diff).forEach(key => {
      updateSubscriberStatus(diff[key]);
    });

    // Add and mailled new subscribers if needed.
    this.state.newSubscribers.forEach(async subscriber => {
        // Add user, get new ID.
        subscriber.id = await addSubscriber(subscriber);
    });

    // Save state.
    this.setState(
      state => ({
        newSubscribers: [],
        prevSubscribers: that.ArrayCopy(state.subscribers)
      }),
      () => this.handleToggleSubscribersEditMode(new MouseEvent(null))
    );
  }

  /**
   * Handle new subscriber on current session.
   * @param {string} login user login.
   * @param {string} status attendance status.
   */
  async handleAddSubscriber(login, status) {
    let inscription = {
      dateInsc: new Date().toISOString(),
      evaluation: false,
      sessionFormation: this.state.sessions[0].sessionFormation,
      statutInscription: {
        id: subscriberStatus.getIndex()[subscriberStatusEnum[status]],
        libelle: subscriberStatusEnum[status]
      },
      user: this.state.users.filter(u => u.login === login)[0]
    };

    this.setState((state) => { return {
      newSubscribers: [...state.newSubscribers, inscription],
      subscribers: [...state.subscribers, inscription]
    }});
  }

  /**
   * Event rised when a user delete a session.
   * @param {number} id session ID.
   */
  async handleDeleteSession() {
    try {
      await ApiCall.delete(endpoints(this.state.selectedId).DELETEINSCRIPTIONS, {});
      await ApiCall.delete(endpoints(this.state.selectedId).DELETESESSION, {});
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ selectedId: null });
      this.props.history.push('/sessions-list');
    }
  }

  handleOpenConfirmDeleteModal(id) {
    this.setState({
      isConfirmDeleteModalOpen: true,
      selectedId: id
    });
  }

  handleCloseConfirmDeleteModal() {
    this.setState({
      isConfirmDeleteModalOpen: false,
      selectedId: null
    });
  }

  handleCloseMessageSentModal() {
    this.setState({
      isMessageSentModalOpen: false
    });
  }

  /**
   * Event rised when a user changed subscriber status.
   * @param {object} e event object.
   * @param {number} subscriberId subscriber ID to request.
   */
  handleChangeSubscriberStatus(e, subscriberId) {
    // Get the modified subscriber object.
    const subscriber = this.state.subscribers.filter(
      subscriber => subscriber.id === subscriberId
    );
    // Udpate status value.
    subscriber[0].statutInscription = {
      id: subscriberStatus.getIndex()[e.target.value],
      libelle: e.target.value
    };
    // Set state.
    this.setState(state => ({ subscribers: state.subscribers }));
  }

  handleCancelChanges() {
    this.getSession(this.state.prevSessions);
    this.setState(state => ({ detailsEditMode: false, editSessionAddress: state.sessions[0].sessionFormation.lieu }));
  }

  handleDurationChange(index, duration) {
    let hour = Math.floor(duration.hour);
    let minutes = Math.round((duration.minutes / 60) * 100) / 100;

    let sessions = this.state.sessions.slice();

    sessions[index].duree = hour + minutes;

    this.getSession(sessions);
  }

  handleDateChange(moment, id) {

    let sessions = this.state.sessions.slice();

    sessions[id].dateSession = new Date(moment).toISOString();
    sessions[id].sessionFormation.dateSession = new Date(moment).toISOString();

    this.getSession(sessions);
  }

  handleAddDate() {
    let sessions = this.state.sessions.slice();
    sessions.push(Object.assign({}, sessions[sessions.length - 1]));

    sessions[sessions.length - 1].id = null;

    sessions[sessions.length - 1].dateSession = this.state.today.toISOString();

    sessions[sessions.length - 1].sessionFormation.dateSession = this.state.today.toISOString();

    this.getSession(sessions);
  }

  async handleDeleteDate(index) {
    let sessions;

    /**
     * A session must have at least one planification
     */
    if (this.state.sessions.length > 1) {
      /**
       *  if id == null then this new planification has never been sent to the backend, then just splice it from the sessions array
       */
      if (this.state.sessions[index].id != null && this.state.planificationToDelete.find((plan) => {return  plan === this.state.sessions[index].id}) === undefined) {
        try {
          this.setState((state) => { return {
              planificationToDelete: [...state.planificationToDelete.slice(), this.state.sessions[index].id]
           }})

        } catch (e) {
          console.error(e);
        }
      }
      sessions = this.state.sessions.slice();
      sessions.splice(index, 1);
      this.getSession(sessions);
    }
  }

  async handleSessionUpdate(form) {
    const that = this;

    if (!form.checkValidity() || form.formateur.value.length <= 0) {
      form.classList.add('was-validated');
      return;
    }

    /**
     * Check any dates modifications.
     * Can not change date of an openned session ( if at least someone has subscribed ) !!! //
     */
    const nbSubscribers = this.getNbSubscribers();
    if (nbSubscribers > 0) {
      // Check added or removed dates.
      if(Object.keys(this.state.sessions).length !== Object.keys(this.state.prevSessions).length) {
        this.props.loadErrors({id: 'sessionUnauthorizedUpdateException', date: Date.now()});
        return;
      }

      // Check changed dates.
      const diff = Object.keys(this.state.sessions).reduce((diff, key) => {
        if (isNullOrUndefined(that.state.prevSessions[key]) ||
          that.state.prevSessions[key].dateSession === that.state.sessions[key].dateSession)
          return diff;
        return { ...diff, [key]: that.state.sessions[key] };
      }, {});

      if(Object.keys(diff).length !== 0) {
        this.props.loadErrors({id: 'sessionUnauthorizedUpdateException', date: Date.now()});
        return;
      }
    }

    /**
     * Construct the new sessionFormaton object
     */

    let sessionFormation = Object.assign(
      {},
      this.state.sessions[0].sessionFormation
    );

    // if url is not edit then
    if (this.state.getUrlParam[0] !== this.props.match.params[0])
      sessionFormation.formation = this.props.trainings.find(training => {
        return training.intitule === form.formation.value;
    });
    sessionFormation.formateur = form.formateur.value;
    sessionFormation.statutSession = {
      id: sessionStatus.getIndex()[
        form.statutSession.value || sessionStatusEnum.DRAFT
      ],
      libelle: form.statutSession.value || sessionStatusEnum.DRAFT
    };
    sessionFormation.lieu = this.state.editSessionAddress;
    sessionFormation.nbMax = String(form.nbMax.value);
    sessionFormation.montantHT = String(form.montantHT.value);
    sessionFormation.montantFinanceHT = String(form.montantFinanceHT.value);
    sessionFormation.financement = this.state.financers.find(financer => {
      return parseInt(financer.id, 10) === parseInt(form.financement.value, 10);
    });
    sessionFormation.commentaire = form.commentaire.value;

    /**
     * Perform put/post request to update/create sessionFormation
     */
    try {
      let response;
      let sessions = this.state.sessions.slice();

      this.state.planificationToDelete.forEach(async (plan) => {
        await ApiCall.delete(
          endpoints().MANAGERPLANIFICATION + plan,
          {}
        );
      });

      this.setState({
        planificationToDelete: []
      });

      // if we are editing an existing session
      if (this.state.getUrlParam[0] === this.props.match.params[0])
        response = await ApiCall.put(endpoints().EDITSESSION, sessionFormation);
      else { // else (creating a session)
        response = await ApiCall.post(
          endpoints().EDITSESSION,
          sessionFormation
        );
      }

      sessions[0].sessionFormation = {
        ...response.data,
        dates: this.getPlanifications(this.state.sessions)
      };

      // we set variables in sessionFormation for every sessions object because we have retrieve them via the previous result request
      for (let i = 0; i < sessions.length; i++) {
        sessions[i].sessionFormation.formation = sessions[0].sessionFormation.formation;
        sessions[i].sessionFormation.dateSession = sessions[i].dateSession;
        sessions[i].sessionFormation.id = sessions[0].sessionFormation.id;
      }

      /**
       *  Perform a date session update
       */
      await ApiCall.put(endpoints().EDITDATESESSION, sessions);

      this.setState((state) => ({
        sessions: sessions,
        detailsEditMode: false,
        createMode: false,
        editSessionAddress: state.editSessionAddress
      }));
    } catch (e) {
      console.error(e);
    }
  }

  handlePlaceChange(e) {
    if (this.state.editSessionAddress !== e.target.value) {
      this.setState({
        editSessionAddress: e.target.value
      })
    }
  }

  /**
   * Download attendance checklist.
   * @param {object} session session object.
   */
  handleClickAttendance(e, session, translation) {
    e.preventDefault();
    const prop = 'date';
    const date = getClosestDateToToday(session.dates, prop);
    getAttendanceSheet(
      session.id,
      session.formation.intitule,
      session.formateur,
      date[prop],
      translation
    );
  }

  handleClickModalOpen() {
    this.setState({
      isModalOpen: true
    });
  }

  handleClickModalClose() {
    this.setState({
      isModalOpen: false
    });
  }

  handleContentStateChange(editorState) {
    this.setState({
      editorState
    });
  }

  handleSelectStatusChange(selectOptions) {
    let option_array = [];
    selectOptions.forEach(option => {
      option_array.push(option);
    });

    this.setState({ selectOptions: option_array });
  }

  handleFormSubmit(formEl) {
    if (
      formEl.checkValidity() &&
      this.state.selectOptions.length > 0
    ) {
      ApiCall.put(endpoints().MAILSUBSCRIBERSESSION, [
        [this.state.sessions[0].sessionFormation.id],
        [
          formEl['subscriber-mail-object'].value,
          draftToHtml(this.state.editorState)
        ],
        this.state.selectOptions.map(option => {
          return subscriberStatus.getIndex()[option.label];
        }),
        this.state.selectOptions.map(option => {
          return option.label;
        })
      ]).then((response) => {
        if (response.status === 200) {
          // Close subscribers contact modal.
          this.handleClickModalClose();
          // Open success message.
          this.setState({ isMessageSentModalOpen: true });
        }
      }).catch(error => {
        console.error(error);
      });
    }
  }

  handleOpenConfirmModal() {
    this.setState({
      isConfirmModalOpen: true
    })
  }

  handleCloseConfirmModal() {
    this.setState({
      isConfirmModalOpen: false
    })
  }

  async handleClickContactNotification() {
    await ApiCall.put(endpoints().NOTIFICATIONOPENSESSION, this.state.sessions[0].sessionFormation);
    this.setState({
      isConfirmModalOpen: false
    })
  }

  getFormerSelect() {
    let former = [];

    let default_former = {
      value: '',
      label:
        <Translate>
          {({ translate }) => (
            translate('sessionDetail.selectFormer')
          )}
        </Translate>
    };

    former.push(default_former);
    this.state.users.forEach(el => {
      former.push({"value": `${el.firstName} ${el.lastName}`, "label": `${el.firstName} ${el.lastName}`});
    });
    return former;
  }

  render() {
    return (
      <div className="d-flex flex-column flex-md-row justify-content-center mt-2">
        <div className="sidebar-element mr-md-2 mb-2 mb-md-0">
          {this.state.detailsEditMode ? (
            <SessionDetailSideBarEdit
              session={this.state.sessions[0].sessionFormation}
              financers={this.state.financers}
              users={this.getFormerSelect()}
              address={this.state.editSessionAddress}

              onSessionUpdate={this.handleSessionUpdate}
              onCancelChanges={this.handleCancelChanges}
              onDateChange={this.handleDateChange}
              onDurationChange={this.handleDurationChange}
              onAddDate={this.handleAddDate}
              onDeleteDate={this.handleDeleteDate}
              onPlaceChange={this.handlePlaceChange}
              trainings={this.props.trainings}
              createMode={this.state.createMode}
              idFormation={
                this.state.idParam ? parseInt(this.state.idParam, 10) : null
              }
            />
          ) : (
            <SessionDetailSideBar
              session={this.state.sessions[0].sessionFormation}
              enableEditMode={this.enableDetailsEditMode}
              nbSubscribers={this.getNbSubscribers()}
              onDeleteSession={this.handleOpenConfirmDeleteModal}
              financers={this.state.financers}
              users={this.state.users}
            />
          )}
        </div>
        <div className="content-element">
          <SessionDetailCard
            session={this.state.sessions[0].sessionFormation}
            subscribers={this.state.subscribers}
            editMode={this.state.subscribersEditMode}
            onToggleEditMode={this.handleToggleSubscribersEditMode}
            onSaveSessionSubscribers={this.handleSaveSessionSubscribers}
            onClickAttendance={this.handleClickAttendance}
            onChangeSubscriberStatus={this.handleChangeSubscriberStatus}
            users={this.state.users}
            onAddSubscriber={this.handleAddSubscriber}
            onContactSubscriber={this.handleClickModalOpen}
            trainings={this.props.trainings}
            createMode={this.state.createMode}
            onOpenConfirmModal={this.handleOpenConfirmModal}
          />
        </div>
        <Modal
          open={this.state.isModalOpen}
          onClose={this.handleClickModalClose}
          center
          closeIconSize={16}
          closeOnOverlayClick={false}
          classNames={{
            modal: 'modal-content',
            overlay: 'modal-overlay',
            closeButton: 'modal-closebutton clickable'
          }}>
          <ContactSubscribers
            content={this.state.editorState}
            onContentStateChange={this.handleContentStateChange}
            onSelectStatusChange={this.handleSelectStatusChange}
            listStatus={this.state.listStatus}
            onFormSubmit={this.handleFormSubmit}
          />
        </Modal>
        <ConfirmModal
          isModalOpen={this.state.isConfirmModalOpen}
          onClickModalClose={this.handleCloseConfirmModal}
          onConfirm={this.handleClickContactNotification}
          onCancel={this.handleCloseConfirmModal}>
            <Translate id="confirmModal.onNotification" />
        </ConfirmModal>
        <ConfirmModal
          classNames={{confirmButton: 'btn-warning'}}
          isModalOpen={this.state.isConfirmDeleteModalOpen}
          onClickModalClose={this.handleCloseConfirmDeleteModal}
          onConfirm={this.handleDeleteSession}
          onCancel={this.handleCloseConfirmDeleteModal}>
            <Translate id="confirmModal.onDeleteSession" />
        </ConfirmModal>
        <AlertModal
          isModalOpen={this.state.isMessageSentModalOpen}
          onClickModalClose={this.handleCloseMessageSentModal}
          onOkClick={this.handleCloseMessageSentModal}>
           <Translate id="alertModal.onSubscribeMessageSuccess" />
        </AlertModal>
      </div>
    );
  }
}

SessionContainer.propTypes = {
  session: PropTypes.object,
  trainings: PropTypes.array,
  getTrainings: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
  loadErrors: PropTypes.func
};

const mapStateToProps = state => ({
  user: state.user,
  trainings: state.trainings,
  loadErrors: state.loadErrors
});

const mapDispatchToProps = dispatch => {
  return {
    getTrainings: () => {
      dispatch(getTrainings());
    },
    loadErrors: (error) => {
      dispatch(loadErrors(error));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionContainer);
