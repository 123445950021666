/* eslint-disable jsx-a11y/href-no-hash */

import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

import { Icon } from '../../shared/Icons/Index';

export const SubmitButton = props => {
  return (
    <Translate>
      {({ translate }) => (
        <button onClick={props.onClick} className={props.className}>
          {props.icon && (
            <Icon
              icon={props.icon}
              className="icon-pictogram"
              title={props.label && translate(props.label)}
            />
          )}
          <span>{props.label && translate(props.label)}</span>
        </button>
      )}
    </Translate>
  );
};

SubmitButton.defaultProps = {
  className: '',
  type: 'submit'
};

SubmitButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.any
};
