import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Moment from 'react-moment';
import StarRatings from 'react-star-ratings';
import { Translate } from 'react-localize-redux';

import { isNullOrUndefined } from '../../../utils/checking.js';
import { FilterButton, ClickButton } from '../../shared/Buttons/Index';
import sessionStatus, { sessionStatusEnum } from '../../../enums/sessionStatus';
import roles, { roleEnum } from '../../../enums/roles';
import { Icon, Icons } from '../../shared/Icons/Index';

import '../../../styles/components/layers/Sessions/SessionCard.css';

const SessionCard = props => {
  /**
   * Get HTML render for comment icon with tooltips.
   */
  const getCommentIcon = () => {
    var element = {
      [sessionStatusEnum.OPEN]: (
        <div
          className={`d-flex mr-1 align-items-center ${
            !isNullOrUndefined(props.session.commentaire) && props.session.commentaire.length > 0
              ? 'displayed'
              : 'hidden'
          }`}
          data-for="commentTip"
          data-tip={props.session.commentaire}>
          <Icon
            icon={Icons.COMMENT}
            className="icon-size-medium icon-comment"
          />
        </div>
      ),
      default: <div />
    };

    return element[props.session.statut] || element['default'];
  };

  const label =
    props.registered === false
      ? 'sessionCard.btnRegister'
      : 'sessionCard.btnUnregister';

  /**
   * Render session title.
   */
  const sessionTitle = () => {
    if (roles.hasAbility(props.user, roleEnum.ADMIN)) {
      return (
      <Link to={'/sessions-list/detail/edit/' + props.session.id}>
        <span className="session-card-label">
          {props.session.intitule || ''}
        </span>
      </Link>);
    } else {
      return (
      <span className="session-card-label">
        {props.session.intitule || ''}
      </span>);
    }
  }

  return (
    <div
      className={`session-card session-card-${props.session.id} rounded p-2 mb-2 ${
        props.registered ? 'register' : ''
      }`}>
      <div className="d-flex mb-1">
        <div className="d-flex align-items-center mr-auto">
          {sessionTitle()}
        </div>
        {getCommentIcon()}
        <div className="d-flex align-items-center">
          <Translate>
            {({ translate }) => (
              <Icon
                icon={Icons.USER}
                className="icon-size-medium mr-1"
                title={translate('sessionCard.nbSubcribers')}
              />
            )}
          </Translate>
          <span className="nowrap">
            {props.session.numsub || 0} / {props.session.nbmax || '??'}
          </span>
        </div>
      </div>
      <div
        className={`d-flex mb-1 flex-column flex-sm-row toolbar-element ${
          props.registered ? 'register' : ''
        }`}>
        <div className="d-flex flex-column flex-md-row ">
          <dl>
            <dt>
              <Translate id="sessionCard.former" />
            </dt>
            <dd>{props.session.formateur || ''}</dd>
            <dt>
              <Translate id="sessionCard.duration" />
            </dt>
            <dd>
              <Translate
                id="sessionCard.durationFormat"
                data={{
                  hour: props.session.totalduration.Hours || '0',
                  minute: props.session.totalduration.Minutes || '00'
                }}
              />
            </dd>
            {roles.hasAbility(props.user, roleEnum.ADMIN) && [
              <dt key={0}>
                <Translate id="sessionCard.status" />
              </dt>,
              <dd
                key={1}
                className={`status-color ${sessionStatus.getColor(
                  props.session.statut
                )}`}>
                {props.session.statut}
              </dd>
            ]}
          </dl>
        </div>
        <div
          className={`
            ml-auto
            ${
              props.session.statut === sessionStatusEnum.CLOSE
                ? 'displayed'
                : 'hidden'
            }
            `}>
          <StarRatings
            rating={parseFloat(props.session.rating) || 0}
            isAggregateRating
            starRatedColor="ee8a2b"
            starDimension="16px"
            starSpacing="1px"
            name="rating"
          />
        </div>
      </div>
      <div className="row no-gutters">
        <div className="col">
          <div className="row col-sm-12 col-md-12 no-gutters">
            <h3 className="col-xs-12 col-sm-3">
              {props.session.dates.length > 1 ? (
                <Translate id="sessionCard.dates" />
              ) : (
                <Translate id="sessionCard.date" />
              )}
            </h3>
            <ul className="col-xs-12 col-sm-9">
              {props.session.dates &&
                props.session.dates.map((date, index) => (
                  <li key={index}>
                    <Moment
                      parse="YYYY-MM-DDTHH:mm:ssZ"
                      format="DD/MM/YYYY HH:mm">
                      {date.date}
                    </Moment>{' '}
                    (
                    <Translate
                      id="sessionCard.durationFormat"
                      data={{
                        hour: date.duration.Hours || '0',
                        minute: date.duration.Minutes || '00'
                      }}
                    />
                    )
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className="col">
          <div className="row col-sm-12 col-md-12 no-gutters">
            <h3 className="col-xs-12 col-sm-3">
              <Translate id="sessionCard.place" />
            </h3>
            <div className="col-xs-12 col-sm-9 wrap-word">{props.session.lieu}</div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mb-1">
        <FilterButton
          category={{ isSelected: false, name: props.session.category.libelle }}
        />
      </div>
      {props.session.statut === sessionStatusEnum.OPEN ? (
        <div
          className={`toolbar-element ${
            props.registered ? 'register' : ''
          }`}>
          <nav>
            <ul className={`d-flex ${roles.hasAbility(props.user, roleEnum.ADMIN) ? "justify-content-around" : ""}`}>
              <li>
                <ClickButton
                  label={label}
                  icon={!props.registered ? Icons.ADD : Icons.REMOVE}
                  onClick={e => {
                    e.preventDefault();
                    (!props.registered) ?
                      props.onSubscription(props.session.id) :
                      props.onUnsubscription(props.session);
                  }}
                />
              </li>
              {roles.hasAbility(props.user, roleEnum.ADMIN) && (
                <li>
                  <Translate>
                    {({ translate }) => (
                      <ClickButton
                        label="sessionCard.btnAttendantList"
                        icon={Icons.CHECKLIST}
                        onClick={e =>
                          props.onClickAttendance(
                            e,
                            props.session,
                            Object.values(
                              translate([
                                'attendanceSheet.title',
                                'attendanceSheet.training',
                                'attendanceSheet.former',
                                'attendanceSheet.firstname',
                                'attendanceSheet.lastname',
                                'attendanceSheet.attendance'
                              ])
                            )
                          )
                        }
                      />
                    )}
                  </Translate>
                </li>
              )}
            </ul>
          </nav>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

SessionCard.propTypes = {
  session: PropTypes.object,
  subscription: PropTypes.array,
  onClickAttendance: PropTypes.func,
  className: PropTypes.string,
  user: PropTypes.object,
  onSubscription: PropTypes.func,
  onUnsubscription: PropTypes.func,
  registered: PropTypes.bool
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(SessionCard);
