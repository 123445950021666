import * as types from './actionTypes';
import ApiCall from '../../api/api';
import { endpoints } from '../../api/endpoints';
import { loadAPIErrors } from '../actions/error';
import roles, { roleEnum } from '../../enums/roles';

export const getTrainingsSuccess = trainings => ({
  type: types.GET_TRAININGS_SUCCESS,
  trainings
});

export function getTrainings(user) {
  return dispatch => {
    const endpoint = roles.hasAbility(user, roleEnum.ADMIN) ?  endpoints().GETTRAININGS : endpoints().GETACTIVETRAININGS;
    return ApiCall.get(endpoint, {sort:'id,desc'})
      .then(response => {
        dispatch(getTrainingsSuccess(response.data));
      })
      .catch(error => {
        throw error;
      });
  };
}

export const updateTrainingSuccess = training => ({
  type: types.UPDATE_TRAINING_SUCCESS,
  training
});

export function updateTraining(data) {
  return function(dispatch) {
    return ApiCall.put(endpoints().PUTTRAINING, data)
      .then(response => {
        dispatch(updateTrainingSuccess(response.data));
      })
      .catch(error => {
        throw error;
      });
  };
}

export const addTrainingSuccess = training => ({
  type: types.ADD_TRAINING_SUCCESS,
  training
});

export function addTraining(data) {
  return function(dispatch) {
    return ApiCall.post(endpoints().POSTTRAINING, data)
      .then(response => {
        dispatch(updateTrainingSuccess(response.data));
      })
      .catch(error => {
        throw error;
      });
  };
}

export const deleteTrainingSuccess = id => ({
  type: types.DELETE_TRAINING_SUCCESS,
  id
});

export function deleteTraining(id) {
  return function(dispatch) {
    return ApiCall.delete(endpoints(id).DELETETRAINING, {})
      .then(() => {
        dispatch(deleteTrainingSuccess(id));
      })
      .catch(error => {
        dispatch(loadAPIErrors(error));
      });
  };
}