import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';

import AlertModal from '../Modal/AlertModal';
import { clearError } from '../../../redux/actions/error';
import { headersEnum } from '../../../enums/headers';
import { logout } from '../../../redux/actions/auth';

import { isEmpty } from '../../../utils/checking';

import '../../../styles/components/shared/Error/ErrorHandler.css';

class ErrorHandler extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      isErrorModalOpen: false
    }

    this.handleCloseErrorModal = this.handleCloseErrorModal.bind(this);
    this.handleOpenErrorModal = this.handleOpenErrorModal.bind(this);
  }

  /**
   * On render update, check if error messages are up.
   */
  componentDidUpdate(prevProps) {
    // Check for error message.
    if((this.props.error.hasOwnProperty('id') &&
      (!prevProps.error.hasOwnProperty('id') || this.props.error.date !== prevProps.error.date)) ||
      (this.props.error.hasOwnProperty('response') &&
      (!prevProps.error.hasOwnProperty('response') || this.props.error.response.headers.date !== prevProps.error.response.headers.date))) {
        this.handleOpenErrorModal();
    }
  }

  handleOpenErrorModal(){
    this.setState({
      isErrorModalOpen: true
    });
  }

  handleCloseErrorModal(){
    this.setState({
      isErrorModalOpen: false
    }, () => {
      // On session timeout, logout.
      if (this.props.error.response.status === 401) {
        this.props.logout();
      }

      this.props.clearError();
    });
  }

  getMessage(){
    if (!this.props.hasOwnProperty('error') || isEmpty(this.props.error)){
      return null;
    }

    // List of supported http error code.
    const supportedErrorCodeList = [504];

    if (this.props.error.hasOwnProperty('response')
      && this.props.error.response.headers.hasOwnProperty(headersEnum.ERROR))
      return <Translate id={`handleError.${this.props.error.response.headers[headersEnum.ERROR]}`} />;
    else if (this.props.error.hasOwnProperty('id'))
      return <Translate id={`handleError.${this.props.error.id}`} />;
    else if (this.props.error.hasOwnProperty('response')
      && supportedErrorCodeList.includes(this.props.error.response.status))
      return  <Translate id={`handleError.${this.props.error.response.status}`} />;
    else
      return <Translate id="handleError.defaultMessage" />
  }

  render() {
    return <AlertModal
        isModalOpen={this.state.isErrorModalOpen}
        onClickModalClose={this.handleCloseErrorModal}
        onOkClick={this.handleCloseErrorModal}
        classNames={{ modalContent: 'error-handler' }}>
      <h2><Translate id="handleError.title" /></h2>
      <p>{this.getMessage()}</p>
    </AlertModal>
  }
}

ErrorHandler.propTypes = {
  error: PropTypes.object,
  clearError: PropTypes.func,
  logout: PropTypes.func
}

const mapStateToProps = state => ({
  error: state.error
});

const mapDispatchToProps = dispatch => {
  return {
      clearError: () => { dispatch(clearError()); },
      logout: () => { dispatch(logout()); }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorHandler);