import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Translate } from 'react-localize-redux';

import { appIdEnum } from '../../../enums/appId';

import '../../../styles/components/layers/Navbar/Navbar.css';
import logo from '../../../assets/imgs/logo.png';

const Navbar = props => {
  const logger = () => {
    if (props.isAuthenticated)
      return (
        <nav>
          <ul className="d-flex flex-row m-0">
            <li>
              <a href="#empty" className="nav-link" onClick={e => e.preventDefault()}>
                <Translate id="navBar.welcome" data={{ user: props.firstName }} />
              </a>
            </li>
            <li>
              <a href="#empty" className="nav-link" onClick={e => {
                  e.preventDefault();
                  props.onHandleLogout();
                }}>
                <Translate id="navBar.logout" />
              </a>
            </li>
          </ul>
        </nav>
      );
  };

  return (
    <div>
      {/* START DESKTOP NAVBAR */}
      <div className="nav-bar row no-gutters d-none d-md-block">
        {/* START LOGO INGENIANCE */}
        <figure className="figure absolute-over">
          <img
            src={logo}
            className="ingeniance-logo figure-img rounded rounded-circle"
            alt="Logo Ingeniance"
          />
        </figure>
        {/* END LOGO INGENIANCE */}
        {/*  START TOP MENU */}
        <div className="d-flex flex-row align-menu">
          <div className="d-flex flex-grow-2 justify-content-start extend-line-left">
            {props.routes.map((route, index) => (
              <div key={index}>
                <a
                  className={`float-left nav-link align-menu-top tab ${ route.id === appIdEnum.APP_TRAINING ? 'tab tab-active' : ''}`}
                  href={props.onUrlMapping(route.url)}>
                  {route.label}
                </a>
              </div>
            ))}
          </div>
          {/*  START LOGIN BUTTON */}
          <div className="d-flex flex-grow-1 justify-content-end extend-line-right">{logger()}</div>
          {/*  END LOGIN BUTTON */}
        </div>
        {/*  END TOP MENU */}
        {/*  START BOTTOM MENU */}
        <div className="align-menu">
          { props.isAuthenticated && props.routes.map((route, indexP) => (
              route.id === appIdEnum.APP_TRAINING && route.children.map((childRoute, indexC) => (
                <div key={`${indexP}-${indexC}`}>
                  <NavLink
                    className="float-left nav-link sub-menu align-menu-bottom"
                    activeClassName="badge badge-danger"
                    isActive={(match, location) => location.pathname.startsWith(childRoute.url) || (location.pathname === '/' && childRoute.default) }
                    to={childRoute.url}>
                    {childRoute.label}
                  </NavLink>
                </div>
              ))
            ))}
        </div>
        {/* END BOTTOM MENU */}
      </div>
      {/* END DESKTOP NAVBAR */}

      {/* START MOBILE NAVBAR */}
      <div className="d-block d-md-none">
        <nav className="nav-bar nav-bar-mobile navbar navbar-dark">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"/>
            </button>
            <div className="float-right pr-3">{logger()}</div>
        </nav>
        <div className="nav-bar nav-bar-mobile collapse" id="navbarToggleExternalContent">
          <ul className="navbar-nav d-flex flex-column text-center">
          {props.isAuthenticated && props.routes.map((route, indexP) => [
              <li className="nav-item" key={indexP}>
                <a className={`nav-link p-2 ${ route.id === appIdEnum.APP_TRAINING ? 'active' : ''}`}
                  href={route.url}>
                  {route.label}
                </a>
              </li>,
              route.id === appIdEnum.APP_TRAINING && route.children.map((childRoute, indexC) => (
                <li className="nav-item sub-menu" key={`${indexP}-${indexC}`}>
                  <NavLink
                    className="nav-link p-2"
                    activeClassName="active"
                    isActive={(match, location) => location.pathname.startsWith(childRoute.url) || (location.pathname === '/' && childRoute.default) }
                    to={childRoute.url}>
                    {childRoute.label}
                  </NavLink>
                </li>
              ))
          ])}
          </ul>
        </div>
      </div>
      {/* END MOBILE NAVBAR */}
    </div>
  );
};

Navbar.propTypes = {
  firstName: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  onUrlMapping: PropTypes.func,
  onHandleLogout: PropTypes.func,
  routes: PropTypes.array.isRequired
};

export default Navbar;
