import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SessionListSideBar from '../../components/layers/Sessions/SessionListSideBar';
import { selectedCategorySuccess } from '../../redux/actions/categories';

class SessionListSideBarContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      categories: []
    };
  }

  handleClick(categoryName) {
    this.setState({
      categories: this.props.categories.map(category => {
        if (category.name === categoryName.name) {
          category.isSelected = !category.isSelected;
        }
        this.props.updateSelectedCategory(category);

        return category;
      })
    });
  }

  render() {
    return (
      <SessionListSideBar
        categories={this.props.categories}
        onHandleClick={this.handleClick}
        onChangeStatus={this.props.onChangeStatus}
      />
    );
  }
}

SessionListSideBarContainer.propTypes = {
  categories: PropTypes.array.isRequired,
  updateSelectedCategory: PropTypes.func,
  selectedStatus: PropTypes.string,
  onChangeStatus: PropTypes.func
};

const mapStateToProps = state => ({
  categories: state.categories
});

const mapDispatchToProps = dispatch => {
  return {
    updateSelectedCategory: category => {
      dispatch(selectedCategorySuccess(category));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionListSideBarContainer);
