import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';

import { LinkButton } from '../../shared/Buttons/LinkButton';
import roles, { roleEnum } from '../../../enums/roles';
import { Icons } from '../../shared/Icons/Icons';
import sessionStatus from '../../../enums/sessionStatus';
import { FilterButton } from '../../shared/Buttons/Index';

const SessionListSideBar = props => {
  return (
    <div className="session-list">
      <h1 className="px-2 rounded-top">
        <Translate id="sessionList.heading" />
      </h1>
      <div className="block-element px-2 hidden">
        <nav>
          <ul>
            <li>
              <LinkButton icon={Icons.LIST} label="trainingList.mySessions" />
            </li>
            <li>
              <LinkButton icon={Icons.HISTORY} label="trainingList.history" />
            </li>
          </ul>
        </nav>
      </div>
      <div
        className={`block-element px-2 ${
          roles.hasAbility(props.user, roleEnum.ADMIN) ? null : 'rounded-bottom'
        }`}>
        <h2>
          <Translate id="sessionList.categorySearchHeading" />
        </h2>
        {props.categories.map((category, index) => (
          <FilterButton
            key={index}
            category={category}
            onClick={props.onHandleClick}
          />
        ))}
      </div>
      <div className={`block-element px-2 hidden`}>
        <h2>
          <Translate id="sessionList.classicSearchHeading" />
        </h2>
        <Translate>
          {({ translate }) => (
            <input
              className="form-control"
              type="text"
              placeholder={translate('sessionList.searchPlaceHolder')}
            />
          )}
        </Translate>
      </div>
      {roles.hasAbility(props.user, roleEnum.ADMIN) && [
        <div className="block-element px-2" key={0}>
          <h2>
            <Translate id="sessionList.status" />
          </h2>
          <Translate>
          {({translate}) => (
            <select
              className="form-control"
              name="statutSession"
              defaultValue="all"
              onChange={props.onChangeStatus}>
              <option value="all">{translate('sessionList.allStatusOptions')}</option>
              {sessionStatus.getEnums().map(([key, value]) => (
                <option key={key}>{value}</option>
            ))}
            </select>
          )}
          </Translate>
        </div>,
        <div className="block-element px-2 rounded-bottom" key={1}>
          <nav>
            <ul>
              <li>
                <LinkButton
                  icon={Icons.ADD}
                  label="sessionList.createSession"
                  path={'sessions-list/detail/new'}
                />
              </li>
            </ul>
          </nav>
        </div>
      ]}
    </div>
  );
};

SessionListSideBar.propTypes = {
  user: PropTypes.object,
  categories: PropTypes.array.isRequired,
  onHandleClick: PropTypes.func,
  onChangeStatus: PropTypes.func
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(SessionListSideBar);
