import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { Translate } from 'react-localize-redux';
import debounce from 'lodash/debounce';

export const TimeInput = props => {
  const timeFormat =
    props.value &&
    `${props.value.hour.toString().padStart(2, '0') ||
      '00'}:${props.value.minutes.toString().padStart(2, '0') || '00'}`;

  /**
   * Debounce onchange event.
   * Return value as object.
   * @param {*} args onchange event args.
   */
  const debounceEvent = (...args) => {
    const debouncedEvent = debounce(...args);
    return e => {
      e.persist();
      const values = e.target.value.split(':');
      e.target['valueAsObject'] = {
        hour: parseInt(values[0] || 0, 10),
        minutes: parseInt(values[1] || 0, 10)
      };

      return debouncedEvent(e);
    };
  };

  const handleChange = e => {
    props.onChange(e);
  };

  const input = (
    <InputMask
      name={props.name}
      className={`form-control ${props.className}`}
      mask="99:99"
      maskChar={'_'}
      alwaysShowMask={false}
      defaultValue={timeFormat}
      onChange={debounceEvent(handleChange, props.debounceTimer)}
    />
  );

  const label = props.translateId ? (
    <Translate
      id={props.translateId}
      data={{
        hour: props.value && (props.value.hour || '00'),
        minute: props.value && (props.value.minutes || '00')
      }}
    />
  ) : (
    props.value
  );

  return props.editMode ? input : label;
};

const timeShape = {
  hour: PropTypes.number,
  minutes: PropTypes.number
};

TimeInput.defaultProps = {
  className: '',
  debounceTimer: 500
};

TimeInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.shape(timeShape),
  onChange: PropTypes.func,
  className: PropTypes.any,
  editMode: PropTypes.bool,
  debounceTimer: PropTypes.number
};
