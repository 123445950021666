import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Navbar from '../../components/layers/Navbar/Navbar';
import { logout } from '../../redux/actions/auth';
import { getNavInfo } from '../../redux/actions/navInfo';
import AuthService from '../../services/auth.service';

class NavbarContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleLogout = this.handleLogout.bind(this);
    this.urlMapping = this.handleUrlMapping.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.isAuthenticated !== this.props.isAuthenticated && this.props.isAuthenticated) {
        this.props.getNavInfo();
    }
  }

  async handleLogout() {
    await this.props.logout();
  }

  handleUrlMapping(url) {
    const urlApp = new URL(window.location.href);
    const urlNode = new URL(url);

    // Dev env. No need to add token access.
    if ( urlApp.host === 'localhost') { return url; }

    const urlAppSubDomain = urlApp.host.split('.')[0];
    const urlNodeSubDomain = urlNode.host.split('.')[0];

    // Add token access url parameters.
    if (urlAppSubDomain !== urlNodeSubDomain) {
      return url + AuthService.getAccessTokenUrl();
    }

    return url;
  }

  render() {
    return (
      <Navbar
        routes={this.props.navInfo}
        isAuthenticated={this.props.isAuthenticated}
        onUrlMapping={this.handleUrlMapping}
        onHandleLogout={this.handleLogout}
        firstName={this.props.user.principal && this.props.user.principal.firstname}
      />
    );
  }
}

NavbarContainer.propTypes = {
  isAuthenticated: PropTypes.bool,
  history: PropTypes.object,
  user: PropTypes.object,
  logout: PropTypes.func,
  getNavInfo: PropTypes.func,
  navInfo: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.user,
  navInfo: state.navInfo,
});

const mapDispatchToProps = dispatch => {
  return {
    getNavInfo: () => { return dispatch(getNavInfo()); },
    logout: () => { dispatch(logout()); }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavbarContainer);
