import ApiCall from './api';
import { endpoints } from './endpoints';

export const getSessions = () => {
  return ApiCall.get(endpoints().GETALLSESSIONS);
};

export const getScheduledSessions = id => {
  return ApiCall.get(endpoints(id).GETSCHEDULEDSESSIONS);
};
