import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';

import auth from './auth';
import user from './user';
import trainings from './trainings';
import categories from './categories';
import formats from './formats';
import trainingTypes from './trainingTypes';
import trainingStatus from './trainingStatus';
import fundingProviders from './fundingProviders';
import organizations from './organizations';
import error from './error';
import navInfo from './navInfo'

export default combineReducers({
  auth,
  user,
  trainings,
  categories,
  formats,
  trainingTypes,
  trainingStatus,
  fundingProviders,
  organizations,
  error,
  navInfo,
  localize: localizeReducer
});
